import React, { useEffect, useRef, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import User from "../../../assets/img/user.png";
import { ReactComponent as Default } from "../../../assets/svg/student_placeholer.svg";
import {
  Typography,
  Grid,
  TextField,
  IconButton,
  Button,
  Avatar,
  FormControlLabel,
  Badge,
  Radio,
  RadioGroup,
  FormControl,
  Checkbox,
  FormGroup,
} from "@material-ui/core";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import CameraAltRoundedIcon from "@material-ui/icons/CameraAltRounded";
import { Autocomplete } from "@material-ui/lab";
import moment from "moment";
import CustomButtonCircularProgress from "../../CircularProgressBar/index2";

import ImageCropperDialog from "../../ImageCropper/ImageCropperDialog";
import { useAlert } from "react-alert";
import { useSelector } from "react-redux";
import { themeSelectorFn } from "../../../api/Domain/selector";
import { maritalStatusList } from "../../../constants/constants";
import useAxiosApi from "../../../Hooks/useAxiosApi";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(5),
    margin: "auto",
    backgroundColor: "#fff",
    boxShadow: "0px 0px 4px rgba(0, 0, 0, 0.25)",
    borderRadius: "8px",
    //maxWidth: 500,
  },
  image: {
    width: 128,
    height: 128,
  },
  img: {
    margin: "auto",
    display: "block",
    maxWidth: "100%",
    maxHeight: "100%",
    borderRadius: "50%",
  },

  textField: {
    width: "100%",
  },
  floatingLabelFocusStyle: {
    color: "#7e7e7e",
    fontSize: "14px",
  },
  redfloatingLabelFocusStyle: {
    color: "#DC3545",
    fontSize: "14px",
  },
  errorMessage: {
    color: "#DC3545",
  },
  newBtnStyle: {
    maxWidth: "200px",
    borderRadius: "8px",
    height: "45px",
    boxShadow: "none",
  },
}));
function StyledRadio(props) {
  const classes = useStyles();

  return (
    <Radio className={classes.root} disableRipple color="primary" {...props} />
  );
}

export default function BasicInfo({
  studentData,
  basicInfoPatchData,
  basicInfoDataLoading,
  approvalRequestCounts,
  redirectionData,
}) {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const alert = useAlert();
  const history = useHistory();
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const theme_change = useSelector(themeSelectorFn);
  const fileInputRefNew = useRef();
  const [profilePic, setProfilePic] = useState("");
  const [previewImage, setPreviewImage] = useState(
    studentData?.profile_picture ? studentData?.profile_picture : null
  );
  const [selectedImage, setSelectedImage] = useState(null);
  const cityList = useSelector((state) =>
    state?.AllLocations?.details ? state?.AllLocations?.details : []
  );
  const [basicInfo, setBasicInfo] = useState({
    user_id: studentData?.id,
    profession: studentData?.student_user_details?.profession,
    name: `${studentData?.first_name} ${studentData?.last_name}`,
    phone: studentData?.phone,
    email: studentData?.email,
    currentLocation: {
      city_id: studentData?.current_city_id,
      city: studentData?.current_city_name,
    },
    maritalStatus:
      maritalStatusList?.find(
        (item) => item?.name === studentData?.marital_status
      ) ?? null,
    gender: studentData?.student_user_details?.gender,
    dob: studentData?.dob,
    about: studentData?.student_user_details?.about,
    current_address: studentData?.current_address,
    permanent_address: studentData?.permanent_address,
    is_same_address: studentData?.is_same_address,
    guardian_contact: studentData?.guardian_contact,
  });
  useEffect(() => console.log("basicInfo", basicInfo), [basicInfo]);

  const [basicInfoError, setBasicInfoError] = useState({
    name: null,
    phone: null,
    email: null,
    gender: null,
    dob: null,
    about: null,
    permanent_address: null,
    is_same_address: null,
    guardian_contact: null,
  });

  const fileInputClickedNew = (e) => {
    fileInputRefNew.current.click();
  };
  const onSelectFile = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      const reader = new FileReader();
      reader.addEventListener("load", () => setProfilePic(reader.result));
      reader.readAsDataURL(e.target.files[0]);
      setSelectedImage(e.target.files[0]);
      handleClickOpen();
    }
  };
  function dataURLtoFile(dataurl, filename) {
    var arr = dataurl.split(","),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, { type: mime });
  }
  const handleChangeBasicInfo = (e) => {
    const { name, value } = e.target;
    setBasicInfo({
      ...basicInfo,
      [name]: value,
    });
    setBasicInfoError({ ...basicInfoError, [name]: null });
  };

  const validateData = () => {
    if (!basicInfo?.name) {
      alert.error("Name field cannot be left blank");
      return false;
    }
    if (!basicInfo?.phone || String(basicInfo.phone).length !== 10) {
      alert.error("Please enter correct phone number ");
      return false;
    }
    if (!basicInfo?.email) {
      alert.error("Email field cannot be left blank");
      return false;
    }
    return true;
  };

  function dataURLtoFile(dataurl, filename) {
    var arr = dataurl.split(","),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, { type: mime });
  }

  const handleSubmit = () => {
    var formData = new FormData();
    if (validateData()) {
      var new_file;
      var isChanged = false;

      const [firstName, ...lastName] = basicInfo.name
        .split(" ")
        .filter(Boolean);

      if (firstName !== studentData?.profession) {
        formData.append("profession", basicInfo?.profession);
        isChanged = true;
      }

      if (firstName !== studentData?.firstName) {
        formData.append("first_name", firstName);
        isChanged = true;
      }
      if (lastName !== studentData?.lastName) {
        formData.append("last_name", lastName.join(" "));
        isChanged = true;
      }

      if (basicInfo?.phone !== studentData?.phone) {
        formData.append("phone", basicInfo?.phone);
        isChanged = true;
      }
      if (basicInfo?.email !== studentData?.email) {
        formData.append("email", basicInfo?.email);
        isChanged = true;
      }
      console.log(
        "qwertyu",
        basicInfo?.current_address,
        studentData?.current_address,
        basicInfo?.is_same_address,
        studentData?.is_same_address,
        basicInfo?.permanent_address,
        studentData?.permanent_address
      );
      if (basicInfo?.current_address !== studentData?.current_address) {
        formData.append("current_address", basicInfo?.current_address);
        isChanged = true;
      }
      if (basicInfo?.is_same_address !== studentData?.is_same_address) {
        formData.append("is_same_address", basicInfo?.is_same_address);
        isChanged = true;
      }
      if (basicInfo?.permanent_address !== studentData?.permanent_address) {
        formData.append("permanent_address", basicInfo?.permanent_address);
        isChanged = true;
      }
      if (basicInfo?.guardian_contact !== studentData?.guardian_contact) {
        formData.append("guardian_contact", basicInfo?.guardian_contact);
        isChanged = true;
      }
      if (basicInfo?.gender !== studentData?.student_user_details?.gender) {
        formData.append("gender", basicInfo?.gender);
        isChanged = true;
      }
      if (basicInfo?.dob !== studentData?.dob) {
        formData.append("dob", basicInfo?.dob);
        isChanged = true;
      }
      if (basicInfo?.about !== studentData?.student_user_details?.about) {
        formData.append("about", basicInfo?.about);
        isChanged = true;
      }

      if (previewImage !== studentData.profile_picture && selectedImage) {
        new_file = dataURLtoFile(previewImage, selectedImage?.name);
        formData.append("profile_picture", new_file);
        isChanged = true;
      }
      if (basicInfo?.maritalStatus?.name) {
        formData.append("marital_status", basicInfo?.maritalStatus?.id);
      }
      if (basicInfo?.currentLocation?.city) {
        formData.append("current_city", basicInfo?.currentLocation?.city_id);
      }

      if (!isChanged) {
        alert.error("No changes made");
      } else {
        basicInfoPatchData(formData);
      }
    }
  };

  return (
    <>
      <div className={classes.paper}>
        <Grid container spacing={2}>
          <Grid
            item
            xs={12}
            style={
              approvalRequestCounts?.BASIC
                ? { pointerEvents: "none", opacity: 0.4 }
                : {}
            }
          >
            <Grid
              item
              xs={12}
              style={{ display: "flex", justifyContent: "center" }}
            >
              {previewImage ? (
                <div style={{ textAlign: "center" }}>
                  <ImageCropperDialog
                    open={open}
                    setOpen={setOpen}
                    handleClickOpen={handleClickOpen}
                    handleClose={handleClose}
                    profilePic={profilePic}
                    setProfilePic={setProfilePic}
                    setPreviewImage={setPreviewImage}
                  />
                  <div>
                    <input
                      ref={fileInputRefNew}
                      className={classes.fileInput}
                      type="file"
                      id="file"
                      hidden
                      onChange={(e) => {
                        onSelectFile(e);
                      }}
                    />
                    <Badge
                      overlap="circular"
                      anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "right",
                      }}
                      style={{ marginBottom: 20 }}
                      badgeContent={
                        <IconButton
                          style={{
                            background: "#fff",
                            padding: 6,
                            boxShadow: "0 25px 30px 0 rgba(0, 0, 0, 0.1)",
                            border: "solid 1px #f1f1f1",
                          }}
                          onClick={(e) => fileInputClickedNew(e)}
                        >
                          <CameraAltRoundedIcon
                            className={classes.cameraIcon}
                          />
                        </IconButton>
                      }
                    >
                      <Avatar
                        alt="Travis Howard"
                        style={{ height: "130px", width: "130px" }}
                        src={previewImage}
                      />
                    </Badge>
                  </div>
                </div>
              ) : (
                <>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "flex-end",
                    }}
                  >
                    <Default
                      width="128"
                      height="128"
                      alt="img"
                      className={classes.img}
                      fill={theme_change?.main ? theme_change?.main : "#007BFF"}
                    />
                    <ImageCropperDialog
                      open={open}
                      setOpen={setOpen}
                      handleClickOpen={handleClickOpen}
                      handleClose={handleClose}
                      profilePic={profilePic}
                      setProfilePic={setProfilePic}
                      setPreviewImage={setPreviewImage}
                    />
                    <div>
                      <input
                        ref={fileInputRefNew}
                        className={classes.fileInput}
                        type="file"
                        id="file"
                        hidden
                        onChange={(e) => {
                          onSelectFile(e);
                        }}
                      />
                      <Badge
                        overlap="circular"
                        anchorOrigin={{
                          vertical: "bottom",
                          horizontal: "right",
                        }}
                        style={{ marginBottom: 20 }}
                        badgeContent={
                          <IconButton
                            style={{
                              background: "#fff",
                              padding: 6,
                              boxShadow: "0 25px 30px 0 rgba(0, 0, 0, 0.1)",
                              border: "solid 1px #f1f1f1",
                              marginTop: "-40px",
                              marginLeft: "-20px",
                            }}
                            onClick={(e) => fileInputClickedNew(e)}
                          >
                            <CameraAltRoundedIcon
                              className={classes.cameraIcon}
                            />
                          </IconButton>
                        }
                      ></Badge>
                    </div>
                  </div>
                </>
              )}
            </Grid>

            <Grid
              item
              xs={12}
              style={{ display: "flex", flexDirection: "column" }}
            >
              <TextField
                id="outlined-basic"
                label="User Id"
                defaultValue={basicInfo?.user_id}
                size="small"
                disabled
                variant="outlined"
                margin="normal"
                InputLabelProps={{
                  className: classes.floatingLabelFocusStyle,
                }}
              />
              <TextField
                id="outlined-basic"
                label="Profession"
                variant="outlined"
                margin="normal"
                InputLabelProps={{
                  className: classes.floatingLabelFocusStyle,
                }}
                name="profession"
                value={basicInfo?.profession}
                onChange={handleChangeBasicInfo}
                error={basicInfoError.profession ? true : false}
                helperText={<span>{basicInfoError.profession}</span>}
              />

              <TextField
                id="outlined-basic"
                label="Student Name"
                variant="outlined"
                margin="normal"
                InputLabelProps={{
                  className: classes.floatingLabelFocusStyle,
                }}
                name="name"
                value={basicInfo?.name}
                onChange={handleChangeBasicInfo}
                error={basicInfoError.name ? true : false}
                helperText={<span>{basicInfoError.name}</span>}
              />

              <TextField
                id="outlined-basic"
                label="Phone Number"
                margin="normal"
                variant="outlined"
                InputLabelProps={{
                  className: classes.floatingLabelFocusStyle,
                }}
                name="phone"
                value={basicInfo?.phone}
                type="number"
                onChange={handleChangeBasicInfo}
                error={basicInfoError.phone ? true : false}
                helperText={<span>{basicInfoError.phone}</span>}
              />
              <TextField
                id="outlined-basic"
                label="Email ID"
                variant="outlined"
                margin="normal"
                InputLabelProps={{
                  className: classes.floatingLabelFocusStyle,
                }}
                // disabled
                name="email"
                value={basicInfo?.email}
                onChange={handleChangeBasicInfo}
                error={basicInfoError.email ? true : false}
                helperText={<span>{basicInfoError.email}</span>}
              />
              <TextField
                id="outlined-basic"
                label="Current Address"
                variant="outlined"
                margin="normal"
                InputLabelProps={{
                  className: classes.floatingLabelFocusStyle,
                }}
                name="current_address"
                value={basicInfo?.current_address}
                onChange={handleChangeBasicInfo}
                error={basicInfoError.current_address ? true : false}
                helperText={<span>{basicInfoError.current_address}</span>}
              />
              <FormControl component="fieldset">
                <FormGroup
                  row
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "end",
                  }}
                >
                  <Typography variant="body1" style={{ marginRight: "10px" }}>
                    Same as Current Address:
                  </Typography>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={basicInfo?.is_same_address || false}
                        onChange={(e) =>
                          setBasicInfo({
                            ...basicInfo,
                            is_same_address: e.target.checked,
                            permanent_address: e.target.checked
                              ? basicInfo.current_address
                              : "",
                          })
                        }
                        style={{ margin: 0 }}
                        color="primary"
                      />
                    }
                  />
                </FormGroup>
              </FormControl>

              <TextField
                id="outlined-basic"
                label="Permanent Address"
                variant="outlined"
                margin="normal"
                style={{ marginTop: 0 }}
                name="permanent_address"
                InputLabelProps={{
                  className: classes.floatingLabelFocusStyle,
                  shrink: !!basicInfo?.permanent_address,
                }}
                value={basicInfo?.permanent_address}
                onChange={(e) => {
                  setBasicInfo({
                    ...basicInfo,
                    is_same_address: false,
                    permanent_address: e.target.value,
                  });
                }}
                error={basicInfoError.permanent_address ? true : false}
                helperText={<span>{basicInfoError.permanent_address}</span>}
              />

              <TextField
                id="outlined-basic"
                label="Gradian/ Parents Number"
                margin="normal"
                variant="outlined"
                InputLabelProps={{
                  className: classes.floatingLabelFocusStyle,
                }}
                name="guardian_contact"
                value={basicInfo?.guardian_contact}
                type="number"
                onChange={handleChangeBasicInfo}
                error={basicInfoError.guardian_contact ? true : false}
                helperText={<span>{basicInfoError.guardian_contact}</span>}
              />
            </Grid>

            <Grid item xs={12} style={{ marginTop: "10px" }}>
              <Autocomplete
                style={{ width: "100%" }}
                id="combo-box-demo"
                value={basicInfo?.currentLocation}
                onChange={(_, newValue) => {
                  if (newValue) {
                    setBasicInfo({
                      ...basicInfo,
                      currentLocation: newValue,
                    });
                  }
                }}
                options={cityList?.length > 0 ? cityList : []}
                getOptionLabel={(option) => (option?.city ? option?.city : " ")}
                renderOption={(option) => {
                  return (
                    <span style={{ fontSize: 13 }}>{`${option.city}`}</span>
                  );
                }}
                className={classes.fieldStyling1}
                renderInput={(params) => {
                  return (
                    <TextField
                      {...params}
                      label="Current Location"
                      variant="outlined"
                      InputLabelProps={{
                        className: classes.floatingLabelFocusStyle,
                      }}
                    />
                  );
                }}
              />
            </Grid>
            <Grid item xs={12} style={{ marginTop: "20px" }}>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  InputLabelProps={{
                    className: classes.floatingLabelFocusStyle,
                    shrink: true,
                  }}
                  label="DOB"
                  inputVariant="outlined"
                  style={{ width: "100%" }}
                  format="yyyy-MM-dd"
                  value={basicInfo.dob}
                  onChange={(date) => {
                    setBasicInfo({
                      ...basicInfo,
                      dob: moment(date).format("YYYY-MM-DD"),
                    });
                    setBasicInfoError({
                      ...basicInfoError,
                      dob: null,
                    });
                  }}
                  minDate={"1990-11-17"}
                  maxDate={new Date()}
                  disableFuture={true}
                  KeyboardButtonProps={{
                    "aria-label": "change date",
                  }}
                  error={basicInfoError.dob ? true : false}
                  helperText={<span>{basicInfoError.dob}</span>}
                />
              </MuiPickersUtilsProvider>
            </Grid>
            <Grid item xs={12}>
              <FormControl component="fieldset" style={{ marginTop: "10px" }}>
                <RadioGroup
                  aria-label="gender"
                  name="customized-radios"
                  className={classes.customize}
                  color="primary"
                  value={basicInfo?.gender}
                  onChange={(e) =>
                    setBasicInfo({
                      ...basicInfo,
                      gender: e.target.value,
                    })
                  }
                  row
                  style={{
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <Typography variant="body1" style={{ marginRight: "10px" }}>
                    Gender:
                  </Typography>
                  <FormControlLabel
                    value="male"
                    control={<StyledRadio />}
                    label={<Typography variant="body2">Male</Typography>}
                  />
                  <FormControlLabel
                    value="female"
                    control={<StyledRadio />}
                    label={<Typography variant="body2">Female</Typography>}
                  />
                  <FormControlLabel
                    value="other"
                    control={<StyledRadio />}
                    label={<Typography variant="body2">Other</Typography>}
                  />
                </RadioGroup>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <Autocomplete
                style={{ width: "100%" }}
                id="combo-box-demo"
                value={basicInfo?.maritalStatus}
                onChange={(_, newValue) => {
                  if (newValue) {
                    setBasicInfo({
                      ...basicInfo,
                      maritalStatus: newValue,
                    });
                  }
                }}
                options={maritalStatusList}
                getOptionLabel={(option) => `${option.name}`}
                renderOption={(option) => {
                  return (
                    <span style={{ fontSize: 13 }}>{`${option.name}`}</span>
                  );
                }}
                className={classes.fieldStyling1}
                renderInput={(params) => {
                  return (
                    <TextField
                      {...params}
                      label="Marital Status"
                      variant="outlined"
                      InputLabelProps={{
                        className: classes.floatingLabelFocusStyle,
                      }}
                    />
                  );
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                style={{ width: "100%" }}
                multiline
                maxRows={6}
                minRows={6}
                inputProps={{
                  className: classes.textfiledrow,
                }}
                id="outlined-basic"
                label="About"
                variant="outlined"
                margin="normal"
                InputLabelProps={{
                  className: classes.floatingLabelFocusStyle,
                }}
                name="about"
                value={basicInfo?.about}
                onChange={handleChangeBasicInfo}
              />
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <div
              style={{
                marginTop: "10px",
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              <Button
                variant="contained"
                color="primary"
                onClick={
                  approvalRequestCounts?.BASIC
                    ? () => {
                        history.push({
                          pathname: `/student-profile-request/${redirectionData?.name.replace(
                            / /g,
                            "_"
                          )}-${redirectionData?.userId}`,
                        });
                      }
                    : handleSubmit
                }
                className={classes.newBtnStyle}
              >
                {basicInfoDataLoading ? (
                  <CustomButtonCircularProgress />
                ) : approvalRequestCounts?.BASIC ? (
                  `Approve Request (${approvalRequestCounts?.BASIC})`
                ) : (
                  "Save"
                )}
              </Button>
            </div>
          </Grid>
        </Grid>
      </div>
    </>
  );
}
