import {
  Button,
  Checkbox,
  Chip,
  FormControl,
  FormControlLabel,
  Grid,
  InputAdornment,
  Paper,
  Radio,
  RadioGroup,
  TextField,
  Typography,
  Box,
  Tooltip,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import {
  KeyboardArrowDownSharp,
  KeyboardArrowUpSharp,
} from "@material-ui/icons";
import CloseIcon from "@material-ui/icons/Close";
import { Autocomplete } from "@material-ui/lab";
import clsx from "clsx";
import moment from "moment";
import PropTypes from "prop-types";
import React, { useCallback, useEffect, useRef, useState } from "react";
import NumberFormat from "react-number-format";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation, useParams } from "react-router-dom";
import pSBC from "shade-blend-color";
import { isGradeSystemFn } from "../../../api/Domain/selector";
import { generateRandomId } from "../../../utility/generateRandomId";
import {
  uniqueArrayWithId,
  uniqueArrayWithoutId,
} from "../../../utility/uniqueArray";
import { years_list } from "../../../utility/yearList";
import ArrowCards from "../../Cards/ArrowCards";
import DegreeSpecializationCustom from "../../DegreeSpecializationCustom";
import PostJobComapny from "../../DialogSection/AddNewCompany/PostJobAddCompany";
import JobDescripition from "../../DialogSection/JobDescription";
import Editor from "../../Editor";
// import Calendar from "../../Input/Calendar/blockedDates";
import Calendar from "../../Input/Calendar/index2";
import ChipCourses from "../ChipCourses";
import UploadDoc from "../../Input/UploadDoc/UploadDoc";
import CollegesPostedJobsDialog from "../../Cards/UniversityPostJob/CollegesPostedJobsDialog";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import CompanyNameDialog from "../../Cards/UniversityPostJob/CompanyNameDialog";
import { CollegeCompanyAPI } from "../../../api/JobPost/action";
import { useAlert } from "react-alert";
import axios from "axios";
import { BackendBaseApi } from "../../../constants/constants";
import CustomButtonCircularProgress from "../../CircularProgressBar/index2";
import AddMultipleCompany from "../../Cards/UniversityPostJob/AddMultipleCompany";
import { entityInfoSelectorFn } from "../../../api/SignIn/selector";
import AddEligibleCoursesDialog from "../../Cards/UniversityPostJob/AddEligibleCoursesDialog";
import { praveshAxiosGetReq } from "../../../api/BaseApi/apiBase";
import { checkForToken } from "../../../constants/ApiCall";

const useStyles = makeStyles((theme) => ({
  paper: {
    height: "auto",
    boxShadow: "none",
    borderRadius: "8px",
    paddingBottom: "30px",
    padding: "1px",
    width: "100%",
  },
  leftpaper: {
    height: "auto",
    boxShadow: "none",
    padding: "20px",
    paddingLeft: "35px",
    paddingRight: "35px",
  },
  text: { marginTop: "10px", marginBottom: "10px" },

  rightpaper: {
    height: "auto",
    boxShadow: "none",
    paddingLeft: "15px",
    paddingRight: "15px",
  },
  formControl: {
    marginTop: "10px",
    marginBottom: "20px",
    minWidth: "100%",
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  chip: {
    margin: theme.spacing(0.5),
    color: theme.palette.primary.main,
    backgroundColor: pSBC(0.8, theme.palette.primary.main),
    boxShadow: "0 1px 2px 0 rgba(44, 39, 56, 0.08)",
    border: `solid 0.5px ${theme.palette.primary.main}`,
    borderRadius: "8px",
  },
  jdTemp: {
    color: theme.palette.primary.main,
    cursor: "pointer",
  },
  icon: {
    borderRadius: "50%",
    width: 16,
    height: 16,
    boxShadow:
      "inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)",
    backgroundColor: "#f5f8fa",
    backgroundImage:
      "linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))",
    "$root.Mui-focusVisible &": {
      outline: "2px auto rgba(19,124,189,.6)",
      outlineOffset: 2,
    },
    "input:hover ~ &": {
      backgroundColor: "#ebf1f5",
    },
    "input:disabled ~ &": {
      boxShadow: "none",
      background: "rgba(206,217,224,.5)",
    },
  },
  checkedIcon: {
    backgroundColor: theme.palette.primary.main,
    backgroundImage:
      "linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))",
    "&:before": {
      display: "block",
      width: 16,
      height: 16,
      backgroundImage: "radial-gradient(#fff,#fff 28%,transparent 32%)",
      content: '""',
    },
    "input:hover ~ &": {
      backgroundColor: theme.palette.primary.main,
    },
  },
  customize: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  root: {
    "& .MuiFormLabel-root": {
      color: "red",
    },
  },
  include: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
  },
  floatingLabelFocusStyle: {
    color: "#7e7e7e",
    fontSize: "14px",
  },
  btngrid: {
    display: "grid",
  },
  addbtn: {
    borderRadius: "8px",
    boxShadow: "none",
    display: "flex",
    height: "48px",
  },
  errorMessage: {
    color: "#DC3545",
  },
  upload: {
    "@media only screen and (max-width: 2000px)": {
      marginBottom: "60px",
    },
    "@media only screen and (max-width: 1700px)": {
      marginBottom: "60px",
    },
    "@media only screen and (max-width: 1300px)": {
      marginBottom: "80px",
    },
  },

  rootchip: {
    display: "flex",
    flexWrap: "wrap",
    listStyle: "none",
    paddingLeft: "0px",
    padding: theme.spacing(0.5),
    margin: 0,
  },
  chip: {
    margin: theme.spacing(0.5),
    backgroundColor: pSBC(0.8, theme.palette.primary.main),
    marginLeft: 0,
    borderRadius: "8px",
  },
  placementBox: {
    border: "1px solid #C4C4C4",
    borderRadius: "8px",
    padding: 5,
    position: "relative",
    minHeight: "55px",
    "&:hover": {
      borderColor: "#000",
    },
  },
}));

function StyledRadio(props) {
  const classes = useStyles();

  return (
    <Radio
      className={classes.root}
      disableRipple
      color="default"
      checkedIcon={<span className={clsx(classes.icon, classes.checkedIcon)} />}
      icon={<span className={classes.icon} />}
      {...props}
    />
  );
}
function NumberFormatCustom(props) {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      thousandSeparator
      isNumericString
      thousandsGroupStyle="lakh"
      prefix="₹"
    />
  );
}

NumberFormatCustom.propTypes = {
  inputRef: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};
export default function NewPostJobForm({
  jobDetails,
  setJobDetails,
  jobDetailsError,
  setJobDetailsError,
  loadingCourses,
  placementCycle,
  placementCycleId,
  setPlacementCycle,
  jobDesc,
  setJobDesc,
  salaryBreakup,
  setSalaryBreakup,
  dataSelectedNewFormat,
  setDataSelectedNewFormat,
  qualifications,
  setQualifications,
  coursesArray,
  setCoursesArray,
  specializationInDegreeId,
  setSpecializationInDgegreeId,
  pushArray,
  setPushArray,
  dataSelected,
  setDataSelected,
  conditionsText,
  setConditionsText,
  ppoSalaryBreakup,
  setPpoSalaryBreakup,
  collegeId,
  setFormSubmitted,
  formSubmitted,
  editReason,
  setEditReason,
  editReasonError,
  setEditReasonError,
  selectedData,
  jobAction,
  setJobAction,
  resetPlacementFlag,
  PlacementFlag,
  isRecommended,
  collegesList,
  setCollegesList,
  formAllTextFieldState,
  setFormAllTextFieldState,
  setSearchState,
  searchState,
  listedCompany,
  setListedCompany,
  allCollegeCoursesSet,
  setallCollegeCoursesSet,
  globalCoursesList,
  setGlobalCoursesList,
  collegeCoursesLoader,
  selectedCollegeIds,
  setSelectedCollegeIds,
  setSelectedCollegeChangeTrigger,
  nestedColleges,
  groups,
}) {
  console.log("jobDetails", jobDetails);
  const classes = useStyles();

  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const alert = useAlert();

  const entityInfo = useSelector(entityInfoSelectorFn) || {
    college: "College",
    university: "University",
  };

  const params = useParams();
  const [applicationStartDate, setApplicationStartDate] = useState(
    jobDetails?.application_start_date
  );
  const [applicationEndDate, setApplicationEndDate] = useState(
    jobDetails?.application_end_date
  );
  const [joiningDate, setJoiningDate] = useState(jobDetails?.joining_date);
  const [jobId, setJobId] = useState(null);

  const disabledFields = useSelector(
    (store) => store?.DisableFieldsPostJob?.disabledFields
  );

  const [currentJobLocation, setCurrentJobLocation] = useState(null);
  const selectedCourses = useSelector(
    (state) => state?.commonData?.selectedCourseData?.data
  );
  // const skillList = useSelector((state) =>
  //   state?.AllSkills?.skills ? state?.AllSkills?.skills : []
  // );

  const isGradeSystem = useSelector(isGradeSystemFn);
  useEffect(() => {
    if (selectedCourses?.length > 0) {
      var arr = [...selectedCourses];
      arr.map((item) => {
        item.degrees.forEach((item2) => {
          item2.select_all = false;
          item2.specialization.forEach((item3) => {
            item3.selected = false;
          });
        });
      });
      setCoursesArray(arr);
    }
  }, [selectedCourses?.length]);

  // useEffect(() => {
  //   if (
  //     selectedData?.degree_spec_selected?.length > 0 &&
  //     selectedCourses?.length > 0
  //   ) {
  //     console.log("dataSelected1", selectedData?.degree_spec_selected);
  //     var arr = [...selectedCourses];
  //     arr.map((item) => {
  //       item.degrees.forEach((item2) => {
  //         item2.select_all = checkForDegree(item2);
  //         item2.specialization.forEach((item3) => {
  //           item3.selected = checkForSpec(
  //             item3,
  //             item2.degree_id,
  //             item.education_id,
  //             item2.specialization
  //           );
  //         });
  //       });
  //     });
  //     setCoursesArray(arr);
  //     setDataSelectedNewFormat(selectedData?.degree_spec_selected);
  //     var push_arr = [...pushArray];
  //     push_arr.push(1);
  //     setPushArray(push_arr);
  //   }
  // }, [selectedData]);

  // useEffect(() => {
  //   if (selectedData?.degree_spec_selected?.length > 0) {
  //     var arr = [];
  //     selectedData?.degree_spec_selected?.map((item) => {
  //       item.degrees.map((i) => arr.push(i));
  //     });
  //     setDataSelected(arr);
  //   }
  // }, [selectedData?.degree_spec_selected?.length]);

  useEffect(() => {
    if (jobId) {
      setSalaryBreakup(jobDetails?.salary_breakup);
    }
  }, [jobDetails?.salary_breakup]);

  useEffect(() => {
    if (jobId && jobDetails?.job_description) {
      setJobDesc(
        jobDetails?.job_description ? jobDetails?.job_description : ""
      );
    }
  }, [jobDetails?.job_description]);
  // const checkForDegree = (item) => {
  //   var bool_ = false;

  //   var arr = [...selectedData?.degree_spec_selected];
  //   if (selectedData?.degree_spec_selected?.length > 0) {
  //     arr.map((i) => {
  //       i.degrees.map((internal) => {
  //         if (internal.degree_id === item.degree_id) {
  //           if (internal.specialization.length > 0) bool_ = true;
  //           else bool_ = false;
  //         }
  //       });
  //     });
  //   } else bool_ = false;

  //   return bool_;
  // };

  // const checkForSpec = (item, deg_id, edu_id, spec_arr) => {
  //   var bool_ = false;
  //   var arr = [...selectedData?.degree_spec_selected];
  //   if (selectedData?.degree_spec_selected?.length > 0) {
  //     var edu_index = arr.findIndex((i) => i.education_id === edu_id);
  //     if (edu_index !== -1)
  //       var deg_index = arr[edu_index].degrees.findIndex(
  //         (i) => i.degree_id === deg_id
  //       );

  //     if (edu_index !== -1 && deg_index !== -1) {
  //       arr[edu_index].degrees[deg_index].specialization.map((items) => {
  //         if (items.specialization_id === item.specialization_id) bool_ = true;
  //         // else bool_ = false;
  //       });
  //     }
  //   } else bool_ = false;

  //   return bool_;
  // };
  const [collegesWithoutCompany, setCollegesWithoutCompany] = useState([]);

  const [loadingCompanies, setLoadingCompanies] = useState(false);
  useEffect(() => {
    const handleAddCompany = async () => {
      try {
        if (jobDetails?.company_id && collegesList.length) {
          // setLoader(true);
          const collegeIds = collegesList
            .map((college) => college.id)
            .join(",");
          console.log("collegeIds123", collegeIds, collegesList);
          const url = `${BackendBaseApi.SANCHALAN}api/manage_job/add-college/?college_ids=${collegeIds}&company_id=${jobDetails?.company_id}`;

          const res = await axios.get(url, { withCredentials: true });
          if (res?.data?.success) {
            setJobDetailsError({ ...jobDetailsError, company: null });
            try {
              setCompanyCheckLoader(true);
              handleClose();
              setListedCompany(false);
              setCompanyAdded(false);
              setJobDetailsError({
                ...jobDetailsError,
                company: "no_validation",
              });
              setCollegesWithoutCompany([]);

              const url = `${BackendBaseApi.SANCHALAN}api/manage_job/company-check/?college_ids=${collegeIds}&company_id=${jobDetails?.company_id}`;

              const res = await axios.get(url, { withCredentials: true });
              if (res?.data?.success) {
                setListedCompany(res?.data?.data?.status);
                if (!res?.data?.data?.status) {
                  setJobDetailsError({
                    ...jobDetailsError,
                    company: "no_validation",
                  });
                  setCollegesWithoutCompany(res?.data?.data?.data);
                }
              } else {
                setJobDetails({
                  ...jobDetails,
                  company: null,
                  company_id: null,
                  company_name: null,
                  company_website: null,
                  company_logo: null,
                  company_address: null,
                });
                alert.error("Something went wrong, Try again.");
              }
              setCompanyCheckLoader(false);
            } catch (error) {
              setCompanyCheckLoader(false);
              alert.error("Something went wrong, Try again.");
              throw error;
            }
          } else {
            alert.error("Company not added, Try again.");
          }
          // setLoader(false);
        }
      } catch (error) {
        // setLoader(false);
        alert.error("Company not added, Try again.");

        throw error;
      }
    };
    if (collegesList.length) {
      const collegeIds = collegesList
        .filter((college) => college.checked)
        .map((college) => college.id)
        .join(",");
      console.log("collegeIds", collegeIds, collegesList);

      if (!jobId) {
        setJobDetails({
          ...jobDetails,
          company: null,
          company_id: null,
          company_name: null,
          company_website: null,
          company_logo: null,
          company_address: null,
        });
      } else {
        handleAddCompany();
      }

      setJobDetailsError({ ...jobDetailsError, company: null });

      setCollegesWithoutCompany([]);

      dispatch(
        CollegeCompanyAPI(
          setLoadingCompanies,
          alert,
          history,
          location,
          collegeIds
        )
      );
    }
  }, [collegesList]);

  useEffect(() => {
    if (params.job_id) {
      setJobAction(params.job_id.split("-")[0]);
      setJobId(params.job_id.split("-")[1]);
    }
  }, [params]);

  const documents = [
    "Resume",
    "Cover Letter",
    "Transcript",
    "ID Proof (e.g. Aadhar Card, PAN Card, etc.)",
    "View Reel",
  ];

  const selectedFields = useSelector((state) =>
    jobId
      ? state?.SingleJobData?.details?.college_job_data?.fields
      : state?.JobFields?.details?.job_form_fields
  );

  const companyList = useSelector((state) =>
    state?.AllCompany?.details ? state?.AllCompany?.details : []
  );
  const jobSegmentList = useSelector((state) =>
    state?.AllJobSegments?.details ? state?.AllJobSegments?.details : []
  );
  const jobRolesList = useSelector((state) =>
    state?.AllJobRoles?.details ? state?.AllJobRoles?.details : []
  );
  const cityList = useSelector((state) =>
    state?.AllLocations?.details ? state?.AllLocations?.details : []
  );
  const jobRoundList = useSelector((state) =>
    state?.AllJobRounds?.details ? state?.AllJobRounds?.details : []
  );
  const [groupFieldList, setGroupFieldList] = useState([]);
  const [currentbatch, setCurrentBatch] = useState(null);
  const [currentRound, setCurrentRound] = useState(null);
  const [currentDuration, setCurrentDuration] = useState(null);
  const [venueType, setVenueType] = useState("ONLINE");
  const [venue, setVenue] = useState(null);
  const [typedRoundName, setTypedRoundName] = useState("");
  const [preSelectedJD, setPreSelectedJD] = useState(false);
  const [jdSelectButton, setJdSelectButton] = useState(false);
  const handleDeleteFunc = (index, array, name) => {
    if (name) {
      var arr = [...array];

      arr.splice(index, 1);
      setJobDetails({ ...jobDetails, [name]: arr });
    } else {
      var arr = [...array];

      arr.splice(index, 1);
      setPlacementCycle(arr);
    }
  };

  useEffect(() => {
    if (selectedFields?.length > 0) {
      var result = selectedFields.reduce(function (r, a) {
        if (a.section) {
          r[a.section] = r[a.section] || [];
          r[a.section].push(a);
        } else {
          r["top"] = r[["top"]] || [];

          r["top"].push(a);
        }

        return r;
      }, Object.create(null));
      setGroupFieldList(result);
    }
  }, [selectedFields]);

  const handleChangeText = (e) => {
    var name = e.target.name;
    setFormAllTextFieldState({
      ...formAllTextFieldState,
      [name]: e.target.value,
    });
    setJobDetails({ ...jobDetails, [name]: e.target.value });
    setJobDetailsError({ ...jobDetailsError, [name]: null });
  };

  useEffect(() => {
    if (applicationStartDate) {
      setJobDetails({
        ...jobDetails,
        application_start_date: applicationStartDate,
      });
      setJobDetailsError({ ...jobDetailsError, application_start_date: null });
    }
  }, [applicationStartDate]);

  useEffect(() => {
    if (applicationEndDate) {
      setJobDetails({
        ...jobDetails,
        application_end_date: applicationEndDate,
      });
      setJobDetailsError({ ...jobDetailsError, application_end_date: null });
    }
  }, [applicationEndDate]);

  useEffect(() => {
    if (joiningDate) {
      setJobDetails({ ...jobDetails, joining_date: joiningDate });
      setJobDetailsError({ ...jobDetailsError, joining_date: null });
    }
  }, [joiningDate]);

  useEffect(() => {
    if (jobDesc) {
      setJobDetails({ ...jobDetails, job_description: jobDesc });
      setJobDetailsError({ ...jobDetailsError, job_description: null });
    }
  }, [jobDesc]);

  useEffect(() => {
    if (salaryBreakup) {
      setJobDetails({ ...jobDetails, salary_breakup: salaryBreakup });
    }
  }, [salaryBreakup]);

  useEffect(() => {
    if (ppoSalaryBreakup) {
      setJobDetails({ ...jobDetails, salary_breakup_ppo: ppoSalaryBreakup });
    }
  }, [ppoSalaryBreakup]);

  useEffect(() => {
    if (conditionsText) {
      setJobDetails({ ...jobDetails, conditions: conditionsText });
    }
  }, [conditionsText]);

  // useEffect(() => {
  //   console.log("dataSelectedNewFormat", dataSelectedNewFormat);
  //   setJobDetails({
  //     ...jobDetails,
  //     degree_spec_selected: dataSelectedNewFormat,
  //   });
  //   setJobDetailsError({ ...jobDetailsError, degree_spec_selected: null });
  // }, [dataSelectedNewFormat]);

  const validateRounds = () => {
    var is_valid = true;
    var round_error = null;
    var duration_error = null;
    var venue_error = null;
    if (!currentRound) {
      round_error = "Please enter round";
      is_valid = false;
    }
    if (
      currentRound &&
      currentRound?.name?.toLowerCase()?.includes("others") &&
      !typedRoundName
    ) {
      round_error = "Please Enter Round Name";
      is_valid = false;
    }
    // if (!currentDuration) {
    //   duration_error = "Please enter Duration";
    //   is_valid = false;
    // }
    // if (!venue) {
    //   venue_error = `Please enter your ${venueType === "ONLINE" ? "link" : "address"}`;
    //   is_valid = false;
    // }

    setJobDetailsError({
      ...jobDetailsError,
      rounds: round_error,
      // round_duration: duration_error,
      // venue: venue_error,
    });
    return is_valid;
  };

  const addRounds = () => {
    if (validateRounds()) {
      var arr = [...jobDetails.rounds];
      if (currentRound?.name?.toLowerCase()?.includes("others")) {
        arr.push({
          round_id: currentRound?.id,
          round_code: currentRound?.code,
          round_name: typedRoundName,
          duration: currentDuration,
          type: venueType,
          venue: venue,
          random_round_id: generateRandomId(8),
        });
      } else {
        arr.push({
          round_id: currentRound?.id,
          round_code: currentRound?.code,
          round_name:
            currentRound?.name +
            " " +
            getOccurrence(jobDetails?.rounds, currentRound?.id),
          duration: currentDuration,
          type: venueType,
          venue: venue,
          random_round_id: generateRandomId(8),
        });
      }
      setCurrentRound(null);
      setCurrentDuration("");
      setVenueType("ONLINE");
      setVenue("");
      setJobDetails({ ...jobDetails, rounds: arr });
    }
  };

  function getOccurrence(array, value) {
    var count = 0;
    array.forEach((v) => v.round_id === value && count++);
    return count + 1;
  }

  const checkForList = (key) => {
    switch (key) {
      case "job_segment":
        return jobSegmentList;
      case "job_role":
        return jobRolesList;
      default:
        return [];
    }
  };

  const [showRoundList, setShowRoundList] = useState(true);
  const [roundList, setRoundList] = useState([]);
  const [currentSkills, setCurrentSkills] = useState([]);

  useEffect(() => {
    if (!showRoundList) setRoundList(jobDetails?.rounds.slice(0, 4));
    else setRoundList(jobDetails?.rounds);
  }, [showRoundList, jobDetails?.rounds?.length]);

  const handleDeleteRounds = (item) => {
    var arr = [...jobDetails?.rounds];
    var index = arr.findIndex(
      (x) => x.random_round_id === item.random_round_id
    );
    if (index !== -1) {
      arr.splice(index, 1);
      setJobDetails({ ...jobDetails, rounds: arr });
    }
  };

  const UpdateDocuments = (event) => {
    const document = event.target.name;
    if (jobDetails?.documents?.includes(document)) {
      let temp = jobDetails?.documents?.filter((doc) => doc !== document);
      setJobDetails({
        ...jobDetails,

        documents: temp,
      });
    } else {
      setJobDetails({
        ...jobDetails,

        documents: [...jobDetails.documents, document],
      });
    }
  };

  useEffect(() => {
    if (jobDetails?.pan_india) {
      setJobDetails({ ...jobDetails, job_location: [] });
      setCurrentJobLocation(null);
    }
  }, [jobDetails?.pan_india]);

  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const getRoleAndSegmentDisabled = (key) => {
    if (disabledFields?.job_role && key === "job_role") {
      return true;
    } else if (disabledFields?.job_segment && key === "job_segment") {
      return true;
    } else {
      return false;
    }
  };

  const [companyCheckLoader, setCompanyCheckLoader] = useState(false);
  // const [listedCompany, setListedCompany] = useState(false);
  const [companyAdded, setCompanyAdded] = useState(false);

  const handleCompanySelect = async (e, newValue) => {
    setJobDetails({
      ...jobDetails,
      company: newValue,
      company_id: newValue?.id,
      company_name: newValue?.name,
      company_website: newValue?.company_website,
      company_logo: newValue?.company_logo,
      company_address: newValue?.company_address,
    });

    setJobDetailsError({ ...jobDetailsError, company: null });

    try {
      setListedCompany(false);
      setCompanyAdded(false);
      setJobDetailsError({
        ...jobDetailsError,
        company: "no_validation",
      });
      setCollegesWithoutCompany([]);

      if (newValue?.company_id && collegesList.length) {
        setCompanyCheckLoader(true);
        const collegeIds = collegesList
          .filter((college) => college.checked)
          .map((college) => college.id)
          .join(",");

        const url = `${BackendBaseApi.SANCHALAN}api/manage_job/company-check/?college_ids=${collegeIds}&company_id=${newValue?.company_id}`;

        const res = await axios.get(url, { withCredentials: true });
        if (res?.data?.success) {
          setListedCompany(res?.data?.data?.status);
          if (!res?.data?.data?.status) {
            setJobDetailsError({
              ...jobDetailsError,
              company: "no_validation",
            });
            setCollegesWithoutCompany(res?.data?.data?.data);
          }
        } else {
          setJobDetails({
            ...jobDetails,
            company: null,
            company_id: null,
            company_name: null,
            company_website: null,
            company_logo: null,
            company_address: null,
          });
          alert.error("Something went wrong, Try again.");
        }
        setCompanyCheckLoader(false);
      }
    } catch (error) {
      setCompanyCheckLoader(false);
      alert.error("Something went wrong, Try again.");

      throw error;
    }
  };

  const [openCollege, setOpenCollege] = useState(false);
  const handleClickOpenCollege = () => {
    setOpenCollege(true);
  };
  const handleCollegeClose = () => {
    setOpenCollege(false);
  };

  const [addCompany, setAddCompany] = useState(false);
  const handleClickOpenCollege1 = () => {
    setAddCompany(true);
  };
  const handleCollegeClose1 = () => {
    setAddCompany(false);
  };

  const [skillsList, setSkillsList] = useState([]);

  useEffect(() => {
    handleSkillChange("");
  }, []);

  const isdebounce = (func) => {
    let timer;
    return function (...args) {
      const context = this;
      if (timer) clearTimeout(timer);
      timer = setTimeout(() => {
        timer = null;
        func.apply(context, args);
      }, 500);
    };
  };

  const handleSkillChange = async (value) => {
    if (value || value === "") {
      const url = `api/education/skills?&skill_name=${value}&result_count=50`;
      try {
        const res = await praveshAxiosGetReq(url);
        if (res?.data?.success) {
          setSkillsList(res?.data?.data?.skills);
        } else {
          alert.error(res.data.error);
        }
      } catch (error) {
        checkForToken(error, history, location, alert);
        if (error?.response?.status !== 401) {
          alert.error(error?.response?.data?.error);
        }
        throw error;
      }
    }
  };

  const debounceSkillList = useCallback(isdebounce(handleSkillChange), []);

  function getFieldContent(step, item, index) {
    switch (item.field_type) {
      case "upload-file":
        return (
          <Grid style={{ marginTop: "10px" }} item xs={9}>
            <UploadDoc
              details={jobDetails}
              setDetails={setJobDetails}
              collegeId={collegeId}
              selectedCollegeIds={selectedCollegeIds}
            />
            {jobDetailsError?.upload_doc && (
              <Typography
                variant="h6"
                style={{ color: "#DC3545", fontSize: 14, marginTop: 10 }}
              >
                Please upload the File
              </Typography>
            )}{" "}
          </Grid>
        );
      case "company":
        return (
          <>
            <Grid
              container
              spacing={3}
              style={{
                alignItems: "center",
                marginTop: "0px",
                marginBottom: "7px",
              }}
            >
              <Grid item xs={12}>
                {" "}
                <Autocomplete
                  id="country-select-demo"
                  options={companyList}
                  getOptionLabel={(option) => option?.name}
                  value={jobDetails?.company ? jobDetails?.company : ""}
                  disabled={disabledFields?.company}
                  onChange={handleCompanySelect}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      label="Company*"
                      inputProps={{
                        ...params.inputProps,
                      }}
                      InputLabelProps={{
                        className: classes.floatingLabelFocusStyle,
                      }}
                      error={
                        jobDetailsError.company &&
                        jobDetailsError.company !== "no_validation"
                          ? true
                          : false
                      }
                      helperText={
                        <>
                          {jobDetailsError.company &&
                          jobDetailsError.company !== "no_validation"
                            ? jobDetailsError.company
                            : jobDetails?.company &&
                              (companyCheckLoader ? (
                                <span>
                                  <CustomButtonCircularProgress
                                    isWhite={false}
                                  />
                                </span>
                              ) : (
                                <span>
                                  {listedCompany && companyAdded ? (
                                    <Typography
                                      style={{
                                        color: "#12CB02",
                                        fontSize: "16px",
                                      }}
                                    >
                                      <CheckCircleIcon
                                        style={{ color: "#12CB02" }}
                                      />{" "}
                                      The company has been successfully added to
                                      the selected {` ${entityInfo?.college} `}.
                                    </Typography>
                                  ) : null}
                                  {!listedCompany && (
                                    <>
                                      <Box
                                        style={{
                                          display: "flex",
                                          alignItems: "center",
                                        }}
                                      >
                                        <Typography
                                          style={{
                                            color: "#FF0000",
                                            fontSize: "14px",
                                            display: "flex",
                                            alignItems: "center",
                                            marginLeft: "-14px",
                                          }}
                                        >
                                          <InfoOutlinedIcon
                                            style={{
                                              color: "#FF0000",
                                              fontSize: "18px",
                                              marginRight: 5,
                                            }}
                                          />{" "}
                                          The selected company is not added to
                                          all the selected{" "}
                                          {` ${entityInfo?.college} `}.
                                        </Typography>
                                        <Button
                                          variant="text"
                                          color="primary"
                                          onClick={handleClickOpenCollege}
                                        >
                                          **Click here**
                                        </Button>
                                        <Typography
                                          style={{
                                            color: "#FF0000",
                                            fontSize: "14px",
                                            display: "flex",
                                            alignItems: "center",
                                          }}
                                        >
                                          to see the{" "}
                                          {` ${entityInfo?.college} `} where the
                                          company is missing.
                                        </Typography>
                                        <CompanyNameDialog
                                          open={openCollege}
                                          handleClose={handleCollegeClose}
                                          collegesList={collegesWithoutCompany}
                                          handleClickOpenCollege1={
                                            handleClickOpenCollege1
                                          }
                                          companyName={
                                            jobDetails?.company?.company_name
                                          }
                                        />
                                        <AddMultipleCompany
                                          open={addCompany}
                                          handleClose={handleCollegeClose1}
                                          collegesList={collegesWithoutCompany}
                                          jobDetails={jobDetails}
                                          setListedCompany={setListedCompany}
                                          jobDetailsError={jobDetailsError}
                                          setJobDetailsError={
                                            setJobDetailsError
                                          }
                                          setCollegesWithoutCompany={
                                            setCollegesWithoutCompany
                                          }
                                          setCompanyCheckLoader={
                                            setCompanyCheckLoader
                                          }
                                          setJobDetails={setJobDetails}
                                          setCompanyAdded={setCompanyAdded}
                                        />
                                      </Box>
                                    </>
                                  )}
                                </span>
                              ))}
                        </>
                      }
                    />
                  )}
                />
              </Grid>
              {!disabledFields?.company && (
                <PostJobComapny
                  collegeId={collegeId}
                  setJobDetails={setJobDetails}
                  jobDetails={jobDetails}
                  setJobDetailsError={setJobDetailsError}
                  isRecommended={isRecommended}
                />
              )}
            </Grid>
          </>
        );
      case "job_type":
        return (
          <>
            {/* <FormControl component="fieldset">

              <RadioGroup
                name="customized-radios"
                className={classes.customize}
                value={Number(jobDetails?.job_type_id)}
                onChange={(e) => {
                  setJobDetails({
                    ...jobDetails,
                    job_type_name: e.target.name,
                    job_type_id: Number(e.target.value),
                  });
                }}
              >
                <Typography variant="body2" style={{ marginRight: "10px", fontWeight: "bold" }}>
                  Job Type*
                </Typography>
                <FormControlLabel value={7} name="Full Time" control={<StyledRadio />} label={<Typography variant="body2">Full-Time</Typography>} />
                <FormControlLabel value={1} name="Internship" control={<StyledRadio />} label={<Typography variant="body2">Internship</Typography>} />
                <FormControlLabel value={3} name="Part Time" control={<StyledRadio />} label={<Typography variant="body2">Part-Time</Typography>} />
              </RadioGroup>
            </FormControl> */}
          </>
        );

      case "textfield":
        return (
          <>
            {jobAction === "ejd" && item?.key === "company_name" ? (
              <Autocomplete
                id="country-select-demo"
                options={companyList}
                getOptionLabel={(option) => option?.name}
                value={jobDetails?.company ? jobDetails?.company : ""}
                onChange={(e, newValue) => {
                  setJobDetails({
                    ...jobDetails,
                    company: newValue,
                    company_id: newValue?.id,
                    company_name: newValue?.name,
                  });

                  setJobDetailsError({ ...jobDetailsError, company: null });
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    label="Company *"
                    inputProps={{
                      ...params.inputProps,
                    }}
                    InputLabelProps={{
                      className: classes.floatingLabelFocusStyle,
                    }}
                    error={jobDetailsError.company ? true : false}
                    helperText={<span>{jobDetailsError.company}</span>}
                  />
                )}
              />
            ) : (
              <TextField
                variant="outlined"
                //    margin="normal"
                required
                InputLabelProps={{
                  className: classes.floatingLabelFocusStyle,
                }}
                value={
                  formAllTextFieldState.hasOwnProperty([item.key])
                    ? formAllTextFieldState?.[item.key]
                    : jobDetails[item.key]
                    ? jobDetails[item.key]
                    : ""
                }
                disabled={disabledFields?.job_title}
                onChange={handleChangeText}
                fullWidth
                inputProps={{
                  style: {
                    padding: "16px",
                  },
                }}
                id="title"
                style={{ marginBottom: "10px", marginTop: "10px" }}
                label={item?.field_name}
                name={item.key}
                error={jobDetailsError[item.key] ? true : false}
                helperText={<span>{jobDetailsError[item.key]}</span>}
              />
            )}
          </>
        );
      case "autocomplete":
        return (
          item?.key !== "industry" && (
            <div className={classes.formControl}>
              <Autocomplete
                id="country-select-demo"
                options={checkForList(item.key)}
                getOptionLabel={(option) => option.name}
                disabled={getRoleAndSegmentDisabled(item?.key, item.field_type)}
                value={jobDetails[item.key] ? jobDetails[item.key] : ""}
                onChange={(e, newValue) => {
                  setJobDetails({ ...jobDetails, [item.key]: newValue });
                  setJobDetailsError({ ...jobDetailsError, [item.key]: null });
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    label={item?.field_name}
                    inputProps={{
                      ...params.inputProps,
                    }}
                    InputLabelProps={{
                      className: classes.floatingLabelFocusStyle,
                    }}
                    error={jobDetailsError[item.key] ? true : false}
                    helperText={<span>{jobDetailsError[item.key]}</span>}
                  />
                )}
              />
            </div>
          )
        );
      case "application_date":
        return (
          <>
            <Grid container spacing={1} style={{ marginTop: "10px" }}>
              <Grid item xs={4}>
                <Calendar
                  title="Application Start Date"
                  // selectedDate={applicationStartDate}
                  selectedDate={jobDetails?.application_start_date}
                  disabled={disabledFields?.application_date}
                  setSelectedDate={(date) => {
                    // setApplicationStartDate(moment(date).format("YYYY-MM-DD"));
                    setJobDetails({
                      ...jobDetails,
                      application_start_date: moment(date).format("YYYY-MM-DD"),
                    });
                  }}
                  errorMessage={jobDetailsError?.application_start_date}
                  // minDate={new Date()}
                  readOnly={true}
                />
              </Grid>
              <Grid item xs={6}>
                <div style={{ marginLeft: "15px" }}>
                  <Calendar
                    title="Application End Date"
                    // selectedDate={applicationEndDate}
                    selectedDate={jobDetails?.application_end_date}
                    disabled={disabledFields?.application_end_date}
                    setSelectedDate={(date) => {
                      // setApplicationEndDate(moment(date).format("YYYY-MM-DD"));
                      setJobDetails({
                        ...jobDetails,
                        application_end_date: moment(date).format("YYYY-MM-DD"),
                      });
                    }}
                    errorMessage={jobDetailsError?.application_end_date}
                    minDate={
                      jobDetails?.application_start_date &&
                      new Date(jobDetails?.application_start_date)
                        .toISOString()
                        .substring(0, 10)
                    }
                    readOnly={true}
                  />
                </div>
              </Grid>
            </Grid>
          </>
        );
      case "date_single":
        return (
          <>
            <br />
            <br />
            <Calendar
              title="Joining Date"
              selectedDate={jobDetails?.joining_date}
              required="true"
              setSelectedDate={(date) => {
                // setJoiningDate(moment(date).format("YYYY-MM-DD"));
                setJobDetails({
                  ...jobDetails,
                  joining_date: moment(date).format("YYYY-MM-DD"),
                });
              }}
              disabled={disabledFields?.joining_date}
              errorMessage={jobDetailsError?.joining_date}
              minDate={
                jobDetails?.application_end_date
                  ? jobDetails?.application_end_date
                  : new Date()
              }
              readOnly={true}
            />
          </>
        );
      case "campus_drive_date":
        return (
          <>
            <br />
            <br />
            <Calendar
              title="Campus Drive Date"
              selectedDate={jobDetails?.campus_drive_date}
              setSelectedDate={(date) => {
                // setJoiningDate(moment(date).format("YYYY-MM-DD"));
                setJobDetails({
                  ...jobDetails,
                  campus_drive_date: moment(date).format("YYYY-MM-DD"),
                });
              }}
              errorMessage={jobDetailsError?.campus_drive_date}
              minDate={
                jobDetails?.application_end_date
                  ? jobDetails?.application_end_date
                  : new Date()
              }
              readOnly={true}
            />
          </>
        );

      case "job-desc":
        return (
          <>
            {!disabledFields?.desc && (
              <JobDescripition
                jobRolesList={jobRolesList}
                setJobDesc={setJobDesc}
                setPreSelectedJD={setPreSelectedJD}
                setJdSelectButton={setJdSelectButton}
                handleClose={handleClose}
                open={open}
              />
            )}

            <Typography
              variant="body2"
              style={
                jobDetailsError?.job_description
                  ? {
                      marginTop: "20px",
                      marginBottom: "20px",
                      color: "#DC3545",
                    }
                  : { marginTop: "10px", marginBottom: "10px" }
              }
            >
              <b>Job Description*</b> &nbsp; &nbsp;
              {/* <span onClick={handleClickOpen} className={classes.jdTemp}>
                Click Here
              </span>{" "}
              to choose from Job Description Template */}
            </Typography>
            <div className={classes.upload}>
              <Editor
                state={jobDesc}
                setState={setJobDesc}
                formSubmitted={formSubmitted}
                setFormSubmitted={setFormSubmitted}
                disabled={disabledFields?.desc}
                desc={
                  jdSelectButton ? preSelectedJD : selectedData?.job_description
                }
              />
              {jobDetailsError?.job_description && (
                <Typography
                  variant="h6"
                  style={{ color: "#DC3545", fontSize: 14, marginTop: 70 }}
                >
                  Please enter Job Description
                </Typography>
              )}
              {/* <br /> */}
            </div>
          </>
        );
      case "ctc_range":
        return (
          <>
            <Typography
              variant="body2"
              style={{
                marginTop: "10px",
                marginBottom: "5px",
                fontWeight: "bold",
              }}
            >
              {jobDetails?.job_type_id === 7
                ? "CTC Range (Per Annum)"
                : "Stipend Range (Monthly)"}
            </Typography>

            <FormControl component="fieldset">
              {/* <FormLabel component="legend">Gender</FormLabel> */}

              <RadioGroup
                aria-label="gender"
                name="customized-radios"
                className={classes.customize}
                value={jobDetails?.salary_type}
                onChange={(e) => {
                  setJobDetails({ ...jobDetails, salary_type: e.target.value });
                }}
              >
                {/* <Typography variant="body2" style={{ marginRight: "10px" }}>
                  Scoring System Graduation*
                </Typography> */}
                <FormControlLabel
                  value="PAID"
                  control={<StyledRadio />}
                  disabled={disabledFields?.salary_type}
                  label={<Typography variant="body2">Paid</Typography>}
                />
                <FormControlLabel
                  value="UNPAID"
                  control={<StyledRadio />}
                  disabled={disabledFields?.salary_type}
                  label={<Typography variant="body2">Unpaid</Typography>}
                />
                <FormControlLabel
                  value="NOT DISCLOSED"
                  control={<StyledRadio />}
                  disabled={disabledFields?.salary_type}
                  label={<Typography variant="body2">Not Disclosed</Typography>}
                />
              </RadioGroup>
            </FormControl>

            {jobDetails?.salary_type === "PAID" && (
              <>
                <div style={{ display: "flex" }}>
                  <TextField
                    InputLabelProps={{
                      className: classes.floatingLabelFocusStyle,
                    }}
                    variant="outlined"
                    margin="normal"
                    InputProps={{
                      inputComponent: NumberFormatCustom,
                      inputProps: { min: 0 },
                    }}
                    disabled={disabledFields?.ctc_range}
                    // InputLabelProps={{ shrink: true }}
                    label="Minimum"
                    inputProps={{
                      style: {
                        padding: "16px",
                      },
                    }}
                    name="Minimum"
                    value={jobDetails?.ctc_min}
                    onChange={(e) => {
                      setJobDetails({
                        ...jobDetails,
                        ctc_min: Number(e.target.value),
                      });
                      setJobDetailsError({ ...jobDetailsError, ctc_min: null });
                    }}
                    error={jobDetailsError.ctc_min ? true : false}
                    helperText={<span>{jobDetailsError.ctc_min}</span>}
                  />

                  <TextField
                    InputLabelProps={{
                      className: classes.floatingLabelFocusStyle,
                    }}
                    variant="outlined"
                    margin="normal"
                    InputProps={{
                      inputComponent: NumberFormatCustom,
                      inputProps: { min: 0 },
                    }}
                    style={{ marginLeft: "20px" }}
                    disabled={disabledFields?.ctc_range}
                    // InputLabelProps={{ shrink: true }}
                    label="Maximum"
                    inputProps={{
                      style: {
                        padding: "16px",
                      },
                    }}
                    name="Minimum"
                    value={jobDetails?.ctc_max}
                    onChange={(e) => {
                      setJobDetails({
                        ...jobDetails,
                        ctc_max: Number(e.target.value),
                      });
                      setJobDetailsError({ ...jobDetailsError, ctc_max: null });
                    }}
                    error={jobDetailsError.ctc_max ? true : false}
                    helperText={<span>{jobDetailsError.ctc_max}</span>}
                  />
                </div>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={jobDetails?.bonus}
                      onChange={(e) =>
                        setJobDetails({
                          ...jobDetails,
                          bonus: e.target.checked,
                        })
                      }
                      name="checkedB"
                      size="small"
                      color="primary"
                    />
                  }
                  label={
                    <Typography variant="body2" style={{ fontWeight: "bold" }}>
                      Including Bonuses
                    </Typography>
                  }
                />
              </>
            )}
          </>
        );

      case "editor":
        return (
          <>
            {jobDetails?.salary_type === "PAID" && (
              <>
                <Typography
                  variant="h6"
                  style={{ marginTop: "10px", marginBottom: "10px" }}
                >
                  {item?.field_name}
                </Typography>
                <div className={classes.upload}>
                  <Editor
                    state={salaryBreakup}
                    formSubmitted={formSubmitted}
                    setFormSubmitted={setFormSubmitted}
                    setState={setSalaryBreakup}
                    // desc={selectedData?.job_description}
                    desc={null}
                  />
                </div>
              </>
            )}
          </>
        );
      case "location":
        return (
          <>
            <br />

            <FormControlLabel
              control={
                <Checkbox
                  checked={jobDetails?.wfh}
                  onChange={(e) => {
                    setJobDetails({ ...jobDetails, wfh: e.target.checked });
                  }}
                  disabled={disabledFields?.wfh}
                  name="checkedB"
                  size="small"
                  color="primary"
                />
              }
              label={
                <Typography variant="body2" style={{ fontWeight: "bold" }}>
                  Work From Home Available
                </Typography>
              }
            />
            <br />
            <FormControlLabel
              control={
                <Checkbox
                  checked={jobDetails?.hybrid}
                  onChange={(e) => {
                    setJobDetails({
                      ...jobDetails,
                      hybrid: e.target.checked,
                    });
                  }}
                  // disabled where pan india is checked and where any locations are given for inbound/fullfilled job
                  disabled={
                    disabledFields?.pan_india || disabledFields?.location
                  }
                  name="checkedB"
                  size="small"
                  color="primary"
                />
              }
              label={
                <Typography variant="body2" style={{ fontWeight: "bold" }}>
                  Hybrid
                </Typography>
              }
            />
            <br />
            <FormControlLabel
              control={
                <Checkbox
                  checked={jobDetails?.pan_india}
                  onChange={(e) => {
                    setJobDetails({
                      ...jobDetails,
                      pan_india: e.target.checked,
                    });
                  }}
                  // disabled where pan india is checked and where any locations are given for inbound/fullfilled job
                  disabled={
                    disabledFields?.pan_india || disabledFields?.location
                  }
                  name="checkedB"
                  size="small"
                  color="primary"
                />
              }
              label={
                <Typography variant="body2" style={{ fontWeight: "bold" }}>
                  PAN India
                </Typography>
              }
            />
            {/* <FormControl component="fieldset" style={{ marginTop: "10px" }}>
              <RadioGroup row aria-label="position" name="position" defaultValue="top">
                <FormControlLabel
                  value="Pan"
                  control={
                    <Radio
                      color="primary"
                      size="small"
                      checked={jobDetails?.pan_india}
                      onChange={(e) => {
                        setJobDetails({ ...jobDetails, pan_india: e.target.checked });
                      }}
                    />
                  }
                  label={
                    <Typography variant="body2" style={{ fontWeight: "bold" }}>
                      PAN India
                    </Typography>
                  }
                />
              </RadioGroup>
            </FormControl> */}
            <br />

            <Grid
              container
              spacing={3}
              style={{
                alignItems: "center",
                marginTop: "2px",
              }}
            >
              <Grid item xs={9}>
                {" "}
                <Autocomplete
                  id="country-select-demo"
                  options={cityList}
                  getOptionLabel={(option) => option.city}
                  value={currentJobLocation}
                  disabled={jobDetails?.pan_india || disabledFields?.location}
                  onChange={(e, newValue) => {
                    if (newValue) {
                      var arr = [...jobDetails?.job_location];
                      arr.push(newValue);
                      setJobDetails({
                        ...jobDetails,
                        job_location: uniqueArrayWithId(arr, "city_id"),
                      });
                      setCurrentJobLocation(newValue);
                      setJobDetailsError({
                        ...jobDetailsError,
                        job_location: null,
                      });
                    } else {
                      setCurrentJobLocation(null);
                    }
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      label="Job Location"
                      inputProps={{
                        ...params.inputProps,
                      }}
                      InputLabelProps={{
                        className: classes.floatingLabelFocusStyle,
                      }}
                      error={jobDetailsError.job_location ? true : false}
                      helperText={<span>{jobDetailsError.job_location}</span>}
                    />
                  )}
                />
                {jobDetails?.job_location?.length > 0 &&
                  jobDetails?.job_location?.map((item, index) => (
                    <>
                      {" "}
                      <Chip
                        variant="outlined"
                        color="primary"
                        size="small"
                        onDelete={() => {
                          if (disabledFields?.location) {
                            return;
                          }
                          handleDeleteFunc(
                            index,
                            jobDetails?.job_location,
                            "job_location"
                          );
                        }}
                        style={{ marginTop: "10px" }}
                        deleteIcon={<CloseIcon />}
                        label={item.city}
                        className={classes.chip}
                      />
                    </>
                  ))}
              </Grid>
              {/* <Grid item xs={3} className={classes.btngrid}>
                <Button variant="contained" className={classes.addbtn} color="primary">
                  Add Location
                </Button>
              </Grid> */}
            </Grid>
          </>
        );
      case "vacancy":
        return (
          <>
            <br />
            <TextField
              variant="outlined"
              required
              margin="normal"
              type="number"
              InputLabelProps={{
                className: classes.floatingLabelFocusStyle,
                shrink: true,
              }}
              InputProps={{
                inputProps: { min: 1 },
                style: {
                  padding: "16px",
                },
              }}
              disabled={disabledFields?.vacancy}
              onWheel={() => document.activeElement.blur()}
              label="Number of Vacancies"
              name="Number of Vacancies"
              value={jobDetails?.vacancy}
              onChange={(e) => {
                if (parseInt(e.target.value) !== 0) {
                  setJobDetails({
                    ...jobDetails,
                    vacancy: parseInt(e.target.value),
                  });
                  setJobDetailsError({ ...jobDetailsError, vacancy: null });
                }
              }}
              error={jobDetailsError.vacancy ? true : false}
              helperText={<span>{jobDetailsError.vacancy}</span>}
            />
            <br />
            <FormControlLabel
              control={
                <Checkbox
                  size="small"
                  checked={jobDetails?.service_bond}
                  disabled={disabledFields?.service_bond}
                  onChange={(e) => {
                    setJobDetails({
                      ...jobDetails,
                      service_bond: e.target.checked,
                    });
                  }}
                  name="checkedB"
                  color="primary"
                />
              }
              label={
                <Typography variant="body2" style={{ fontWeight: "bold" }}>
                  Include Service Agreement/ Bond
                </Typography>
              }
            />
            {jobDetails?.service_bond && (
              <>
                <Typography
                  variant="h6"
                  style={{ marginTop: "10px", marginBottom: "10px" }}
                >
                  Conditions Text
                </Typography>
                <Editor
                  state={conditionsText}
                  setState={setConditionsText}
                  formSubmitted={formSubmitted}
                  setFormSubmitted={setFormSubmitted}
                  desc={selectedData?.conditions}
                  disabled={disabledFields?.service_bond_conditions}
                />
              </>
            )}
          </>
        );

      case "duration":
        return (
          <>
            <br />
            {jobDetails.job_type_id === 1 && (
              <TextField
                variant="outlined"
                required
                margin="normal"
                type="number"
                InputLabelProps={{
                  className: classes.floatingLabelFocusStyle,
                  shrink: true,
                }}
                InputProps={{
                  inputProps: { min: 1 },
                  style: {
                    padding: "16px",
                  },
                }}
                label="Duration(in months)"
                name="Duration(in months)"
                value={jobDetails?.internship_duration}
                onWheel={() => document.activeElement.blur()}
                onChange={(e) => {
                  if (parseInt(e.target.value) !== 0) {
                    setJobDetails({
                      ...jobDetails,
                      internship_duration: parseInt(e.target.value),
                    });
                    setJobDetailsError({
                      ...jobDetailsError,
                      internship_duration: null,
                    });
                  }
                }}
                error={jobDetailsError.internship_duration ? true : false}
                helperText={<span>{jobDetailsError.internship_duration}</span>}
              />
            )}
          </>
        );
      case "ppo":
        return (
          <>
            {/* <br /> */}
            <>
              <FormControlLabel
                style={{ marginTop: "10px" }}
                control={
                  <Checkbox
                    checked={jobDetails?.ppo}
                    onChange={(e) => {
                      setJobDetails({ ...jobDetails, ppo: e.target.checked });
                    }}
                    name="checkedB"
                    size="small"
                    color="primary"
                  />
                }
                label={
                  <Typography variant="body2" style={{ fontWeight: "bold" }}>
                    PPO After Internship
                  </Typography>
                }
              />
              {jobDetails?.ppo && (
                <>
                  <Typography variant="h6" style={{ marginTop: "10px" }}>
                    CTC Range (Per Annum)
                  </Typography>

                  <FormControl component="fieldset">
                    {/* <FormLabel component="legend">Gender</FormLabel> */}

                    <RadioGroup
                      aria-label="gender"
                      name="customized-radios"
                      className={classes.customize}
                      value={jobDetails?.ppo_salary_type}
                      onChange={(e) => {
                        setJobDetails({
                          ...jobDetails,
                          ppo_salary_type: e.target.value,
                        });
                      }}
                    >
                      {/* <Typography variant="body2" style={{ marginRight: "10px" }}>
                  Scoring System Graduation*
                </Typography> */}
                      <FormControlLabel
                        value="PAID"
                        control={<StyledRadio />}
                        label={<Typography variant="body2">Paid</Typography>}
                      />
                      <FormControlLabel
                        value="NOT DISCLOSED"
                        control={<StyledRadio />}
                        label={
                          <Typography variant="body2">Not Disclosed</Typography>
                        }
                      />
                    </RadioGroup>
                  </FormControl>
                  {jobDetails?.ppo_salary_type === "PAID" && (
                    <>
                      <div style={{ display: "flex" }}>
                        <TextField
                          InputLabelProps={{
                            className: classes.floatingLabelFocusStyle,
                          }}
                          variant="outlined"
                          margin="normal"
                          InputProps={{
                            inputComponent: NumberFormatCustom,
                            inputProps: { min: 0 },
                          }}
                          // InputLabelProps={{ shrink: true }}
                          label="Minimum"
                          inputProps={{
                            style: {
                              padding: "16px",
                            },
                          }}
                          name="Minimum"
                          value={jobDetails?.ppo_ctc_min}
                          onChange={(e) => {
                            setJobDetails({
                              ...jobDetails,
                              ppo_ctc_min: Number(e.target.value),
                            });
                            setJobDetailsError({
                              ...jobDetailsError,
                              ppo_ctc_min: null,
                            });
                          }}
                          error={jobDetailsError.ppo_ctc_min ? true : false}
                          helperText={
                            <span>{jobDetailsError.ppo_ctc_min}</span>
                          }
                        />

                        <TextField
                          InputLabelProps={{
                            className: classes.floatingLabelFocusStyle,
                          }}
                          variant="outlined"
                          margin="normal"
                          InputProps={{
                            inputComponent: NumberFormatCustom,
                            inputProps: { min: 0 },
                          }}
                          // InputLabelProps={{ shrink: true }}
                          label="Maximum"
                          inputProps={{
                            style: {
                              padding: "16px",
                            },
                          }}
                          style={{ marginLeft: 20 }}
                          name="Maximum"
                          value={jobDetails?.ppo_ctc_max}
                          onChange={(e) => {
                            setJobDetails({
                              ...jobDetails,
                              ppo_ctc_max: Number(e.target.value),
                            });
                            setJobDetailsError({
                              ...jobDetailsError,
                              ppo_ctc_max: null,
                            });
                          }}
                          error={jobDetailsError.ppo_ctc_max ? true : false}
                          helperText={
                            <span>{jobDetailsError.ppo_ctc_max}</span>
                          }
                        />
                      </div>

                      <Typography
                        variant="h6"
                        style={{ marginTop: "10px", marginBottom: "10px" }}
                      >
                        Salary Breakup after PPO
                      </Typography>
                      <div className={classes.upload}>
                        <Editor
                          state={ppoSalaryBreakup}
                          setState={setPpoSalaryBreakup}
                          formSubmitted={formSubmitted}
                          setFormSubmitted={setFormSubmitted}
                          desc={selectedData?.salary_breakup_ppo}
                        />
                      </div>
                    </>
                  )}
                </>
              )}
            </>

            {/* <br /> */}
          </>
        );
      case "courses":
        return (
          <>
            {coursesArray?.length > 0 && (
              <>
                <Box
                  className={classes.placementBox}
                  style={{
                    border: "1px solid #C4C4C4",
                    borderColor:
                      !collegesList.filter((college) => {
                        return (
                          college.checked &&
                          college.placementCycles.some((cycle) => cycle.checked)
                        );
                      })?.length && jobDetailsError?.placement_cycle
                        ? "#dc3545"
                        : "#C4C4C4",
                  }}
                >
                  <Box
                    style={{
                      backgroundColor: "white",
                      position: "absolute",
                      top: "-12px",
                      left: 10,
                    }}
                  >
                    <span
                      style={{
                        color:
                          !collegesList.filter((college) => {
                            return (
                              college.checked &&
                              college.placementCycles.some(
                                (cycle) => cycle.checked
                              )
                            );
                          })?.length && jobDetailsError?.placement_cycle
                            ? "#dc3545"
                            : "#7f7f7f",
                        fontSize: "10px",
                        padding: "0 5px",
                      }}
                    >
                      Course Family and Course*
                    </span>
                  </Box>
                  <Box
                    style={{
                      display: "flex",
                      alignItems: "center",
                      paddingLeft: "5px",
                    }}
                  >
                    <div className={classes.rootchip}>
                      {!showMore
                        ? allDegreesSelected
                            ?.slice(0, 5)
                            ?.map((data, index) => (
                              <Chip
                                className={classes.chip}
                                color="primary"
                                variant="outlined"
                                size="small"
                                label={data?.degree_name}
                                // onDelete={() => deleteChip(data?.id)}
                                // deleteIcon={<CloseIcon />}
                              />
                            ))
                        : allDegreesSelected?.map((data, index) => (
                            <Chip
                              className={classes.chip}
                              color="primary"
                              variant="outlined"
                              size="small"
                              label={data?.degree_name}
                              // onDelete={() => deleteChip(data?.id)}
                              // deleteIcon={<CloseIcon />}
                            />
                          ))}
                      {allDegreesSelected?.length > 5 && (
                        <Button
                          color="primary"
                          onClick={() => {
                            setShowMore(!showMore);
                          }}
                        >
                          {" "}
                          {!showMore
                            ? `+${allDegreesSelected?.length - 5} more`
                            : "Hide"}
                        </Button>
                      )}
                    </div>
                  </Box>
                  <Box
                    style={{ position: "absolute", top: "15px", right: "15px" }}
                  >
                    <Typography
                      color="primary"
                      style={{ cursor: "pointer" }}
                      onClick={handleOpenCourse}
                    >
                      Change
                    </Typography>
                  </Box>
                </Box>
                {/* <div style={{ marginTop: "10px", marginBottom: "10px" }}>
                  {
                    <DegreeSpecializationCustom
                      data={dataSelected}
                      setData={setDataSelected}
                      specialization={specializationInDegreeId}
                      setSpecialization={setSpecializationInDgegreeId}
                      coursesArray={coursesArray}
                      pushArray={pushArray}
                      setPushArray={setPushArray}
                      errorMessage={null}
                      loading={loadingCourses}
                      dataSelectedNewFormat={dataSelectedNewFormat}
                      setDataSelectedNewFormat={setDataSelectedNewFormat}
                    />
                  }
                </div>
                <div style={{ marginBottom: "10px" }}>
                  {dataSelected?.length > 0 && (
                    <ChipCourses
                      data={dataSelected}
                      setData={setDataSelected}
                      specialization={specializationInDegreeId}
                      setSpecialization={setSpecializationInDgegreeId}
                      coursesArray={coursesArray}
                      setCoursesArray={setCoursesArray}
                      pushArray={pushArray}
                      qualifications={qualifications}
                      setQualifications={setQualifications}
                    />
                  )}
                </div> */}
              </>
            )}
          </>
        );

      case "skills":
        return (
          <>
            <Grid
              container
              spacing={3}
              style={{
                alignItems: "center",
                marginTop: "7px",
              }}
            >
              <Grid item xs={9}>
                {" "}
                <Autocomplete
                  id="country-select-demo"
                  options={skillsList}
                  getOptionLabel={(option) => option.skill_name}
                  // value={currentSkills}
                  disabled={disabledFields?.skills}
                  onInputChange={(e, newInputValue) => {
                    if (
                      !skillsList.some(
                        (skill) => skill.skill_name === newInputValue
                      )
                    ) {
                      debounceSkillList(newInputValue);
                    }
                  }}
                  onChange={(e, newValue) => {
                    if (newValue) {
                      var arr = [...jobDetails?.skills];
                      arr.push(newValue);
                      setJobDetails({
                        ...jobDetails,
                        skills: uniqueArrayWithId(arr, "skill_id"),
                      });
                      // setCurrentSkills(newValue);
                      setJobDetailsError({
                        ...jobDetailsError,
                        skills: null,
                      });
                    } else {
                      // setCurrentSkills(null);
                    }
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      label="Skills Required"
                      inputProps={{
                        ...params.inputProps,
                      }}
                      InputLabelProps={{
                        className: classes.floatingLabelFocusStyle,
                      }}
                      error={jobDetailsError.skills ? true : false}
                      helperText={<span>{jobDetailsError.skills}</span>}
                    />
                  )}
                />
                <div style={{ marginTop: "10px" }}>
                  {jobDetails?.skills?.length > 0 &&
                    jobDetails?.skills?.map((item, index) => (
                      <>
                        {" "}
                        <Chip
                          variant="outlined"
                          color="primary"
                          size="small"
                          disabled={disabledFields?.skills}
                          onDelete={() =>
                            handleDeleteFunc(
                              index,
                              jobDetails?.skills,
                              "skills"
                            )
                          }
                          deleteIcon={<CloseIcon />}
                          label={item.skill_name}
                          className={classes.chip}
                        />
                      </>
                    ))}
                </div>
              </Grid>
              {/* <Grid item xs={3} className={classes.btngrid}>
                  <Button variant="contained" className={classes.addbtn} color="primary">
                    Add Location
                  </Button>
                </Grid> */}
            </Grid>
          </>
        );
      case "graduation_grade":
        return (
          <>
            {/* <br /> */}
            <Typography variant="h6" style={{ marginBottom: "10px" }}>
              Grades
            </Typography>
            {/* <FormControl component="fieldset">

              <RadioGroup
                defaultValue="female"
                aria-label="gender"
                name="customized-radios"
                className={classes.customize}
                value={jobDetails?.grad_scoring}
                onChange={e => {
                  setJobDetails({
                    ...jobDetails,
                    grad_scoring: e.target.value,
                  });
                }}
              >
                <Typography variant="body2" style={{ marginRight: "10px" }}>
                  Scoring System Current Education*
                </Typography>
                <FormControlLabel
                  value="CGPA"
                  control={<StyledRadio />}
                  label={<Typography variant="body2">CGPA</Typography>}
                />
                <FormControlLabel
                  value="Percentage"
                  control={<StyledRadio />}
                  label={<Typography variant="body2">Percentage</Typography>}
                />
                <FormControlLabel
                  value="GPA"
                  control={<StyledRadio />}
                  label={<Typography variant="body2">GPA out of 4</Typography>}
                />
              </RadioGroup>
            </FormControl> */}
            {/* <br /> */}
            <div style={{ display: "flex", flexDirection: "column" }}>
              <TextField
                variant="outlined"
                margin="normal"
                InputLabelProps={{
                  className: classes.floatingLabelFocusStyle,
                }}
                type="number"
                style={{ width: "50%", marginBottom: 0 }}
                InputProps={{
                  inputProps:
                    jobDetails?.grad_scoring === "Percentage"
                      ? { min: 0, max: 100 }
                      : jobDetails?.grad_scoring === "CGPA"
                      ? { min: 0, max: 10 }
                      : { min: 0, max: 4 },
                  style: {
                    padding: "16px",
                  },
                }}
                label={`Minimum ${
                  isGradeSystem ? "CGPA" : "Percentage"
                } Required (Current Education)`}
                value={jobDetails?.grad_min_score}
                disabled={disabledFields?.grad_min_score}
                onWheel={() => document.activeElement.blur()}
                onChange={(e) => {
                  if (parseInt(e.target.value) !== 0) {
                    setJobDetails({
                      ...jobDetails,
                      grad_min_score: parseFloat(e.target.value),
                    });
                    setJobDetailsError({
                      ...jobDetailsError,
                      grad_min_score: null,
                    });
                  }
                }}
                error={jobDetailsError.grad_min_score ? true : false}
                helperText={<span>{jobDetailsError.grad_min_score}</span>}
              />
              {isGradeSystem ? <span>Enter grade between 1-10</span> : null}
            </div>
            <br />
            <FormControlLabel
              control={
                <Checkbox
                  checked={jobDetails?.backlog}
                  disabled={disabledFields?.backlog}
                  onChange={(e) => {
                    setJobDetails({ ...jobDetails, backlog: e.target.checked });
                  }}
                  name="checkedB"
                  size="small"
                  color="primary"
                />
              }
              label={
                <Typography variant="body2" style={{ fontWeight: "bold" }}>
                  Back Papers Allowed*
                </Typography>
              }
            />
          </>
        );
      case "school_grade":
        return (
          <>
            <br />
            {/*             
            <FormControl component="fieldset">
              

              <RadioGroup
                defaultValue="female"
                aria-label="gender"
                name="customized-radios"
                className={classes.customize}
                value={jobDetails?.senior_sec_scoring}
                onChange={(e) => {
                  setJobDetails({
                    ...jobDetails,
                    senior_sec_scoring: e.target.value,
                  });
                }}
              >
                <Typography variant="body2" style={{ marginRight: "10px" }}>
                  Scoring System 12th Grade*
                </Typography>
                <FormControlLabel value="CGPA" control={<StyledRadio />} label={<Typography variant="body2">CGPA</Typography>} />
                <FormControlLabel value="Percentage" control={<StyledRadio />} label={<Typography variant="body2">Percentage</Typography>} />
                
              </RadioGroup>
            </FormControl> */}

            {/* <br /> */}
            <TextField
              InputLabelProps={{
                className: classes.floatingLabelFocusStyle,
              }}
              InputProps={{
                inputProps:
                  jobDetails?.senior_sec_scoring === "Percentage"
                    ? { min: 0, max: 100 }
                    : jobDetails?.senior_sec_scoring === "CGPA"
                    ? { min: 0, max: 10 }
                    : { min: 0, max: 4 },
                style: {
                  padding: "16px",
                },
              }}
              variant="outlined"
              margin="normal"
              style={{ width: "50%" }}
              type="number"
              label="Minimum Score Required in 12th Grade"
              value={parseFloat(jobDetails?.senior_sec_min_score)}
              onWheel={() => document.activeElement.blur()}
              onChange={(e) => {
                if (parseInt(e.target.value) !== 0) {
                  setJobDetails({
                    ...jobDetails,
                    senior_sec_min_score: parseFloat(e.target.value),
                  });
                  setJobDetailsError({
                    ...jobDetailsError,
                    senior_sec_min_score: null,
                  });
                }
              }}
              error={jobDetailsError.senior_sec_min_score ? true : false}
              helperText={<span>{jobDetailsError.senior_sec_min_score}</span>}
            />
            <br />
            {/* <FormControl component="fieldset" style={{ marginTop: "10px" }}>
             

              <RadioGroup
                defaultValue="female"
                aria-label="gender"
                name="customized-radios"
                className={classes.customize}
                value={jobDetails?.sec_scoring}
                onChange={(e) => {
                  setJobDetails({ ...jobDetails, sec_scoring: e.target.value });
                }}
              >
                <Typography variant="body2" style={{ marginRight: "10px" }}>
                  Scoring System 10th Grade*
                </Typography>
                <FormControlLabel value="CGPA" control={<StyledRadio />} label={<Typography variant="body2">CGPA</Typography>} />
                <FormControlLabel value="Percentage" control={<StyledRadio />} label={<Typography variant="body2">Percentage</Typography>} />
                
              </RadioGroup>
            </FormControl>
            
            <br /> */}

            <TextField
              variant="outlined"
              InputLabelProps={{
                className: classes.floatingLabelFocusStyle,
              }}
              // margin="normal"
              style={{ width: "50%", marginTop: "10px" }}
              type="number"
              InputProps={{
                inputProps:
                  jobDetails?.sec_scoring === "Percentage"
                    ? { min: 0, max: 100 }
                    : jobDetails?.sec_scoring === "CGPA"
                    ? { min: 0, max: 10 }
                    : { min: 0, max: 4 },
                style: {
                  padding: "16px",
                },
              }}
              onWheel={() => document.activeElement.blur()}
              label="Minimum Score Required in 10th Grade"
              value={jobDetails?.sec_min_score}
              onChange={(e) => {
                if (parseInt(e.target.value) !== 0) {
                  setJobDetails({
                    ...jobDetails,
                    sec_min_score: parseFloat(e.target.value),
                  });
                  setJobDetailsError({
                    ...jobDetailsError,
                    sec_min_score: null,
                  });
                }
              }}
              error={jobDetailsError.sec_min_score ? true : false}
              helperText={<span>{jobDetailsError.sec_min_score}</span>}
            />
          </>
        );

      case "batch":
        return (
          <>
            <Grid
              container
              spacing={3}
              style={{
                alignItems: "center",
                marginTop: "10px",
                //  marginBottom: "10px",
              }}
            >
              <Grid item xs={9}>
                {" "}
                <Autocomplete
                  id="country-select-demo"
                  options={years_list}
                  getOptionLabel={(option) => String(option.name)}
                  value={currentbatch}
                  disabled={disabledFields?.batch}
                  onChange={(e, newValue) => {
                    setCurrentBatch(newValue);
                    var arr = [...jobDetails.eligible_batches];
                    arr.push(newValue.value);
                    setJobDetails({
                      ...jobDetails,
                      eligible_batches: uniqueArrayWithoutId(arr),
                    });

                    setJobDetailsError({
                      ...jobDetailsError,
                      eligible_batches: null,
                    });
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      label="Eligible Batches"
                      inputProps={{
                        ...params.inputProps,
                      }}
                      InputLabelProps={{
                        className: classes.floatingLabelFocusStyle,
                      }}
                      error={jobDetailsError.eligible_batches ? true : false}
                      helperText={
                        <span>{jobDetailsError.eligible_batches}</span>
                      }
                    />
                  )}
                />
                <div style={{ marginTop: "10px" }}>
                  {jobDetails?.eligible_batches?.length > 0 &&
                    jobDetails?.eligible_batches?.map((item, index) => (
                      <>
                        {" "}
                        <Chip
                          variant="outlined"
                          color="primary"
                          size="small"
                          disabled={disabledFields?.batch}
                          onDelete={() =>
                            handleDeleteFunc(
                              index,
                              jobDetails?.eligible_batches,
                              "eligible_batches"
                            )
                          }
                          deleteIcon={<CloseIcon />}
                          label={item}
                          className={classes.chip}
                        />
                      </>
                    ))}
                </div>
              </Grid>
              {/* <Grid item xs={3} className={classes.btngrid}>
                <Button variant="contained" className={classes.addbtn} color="primary">
                  Add Batch
                </Button>
              </Grid> */}
            </Grid>
          </>
        );
      case "selection":
        return (
          <>
            <Grid
              container
              spacing={3}
              style={{
                alignItems: "center",
                marginTop: "10px",
                marginBottom: "5px",
              }}
            >
              <Grid item xs={9}>
                {" "}
                {currentRound?.name?.toLowerCase()?.includes("others") ? (
                  <>
                    <TextField
                      variant="outlined"
                      InputLabelProps={{
                        className: classes.floatingLabelFocusStyle,
                      }}
                      margin="normal"
                      style={{ width: "100%" }}
                      type="text"
                      InputProps={{
                        // inputProps: jobDetails?.sec_scoring === "Percentage" ? { min: 0, max: 100 } : jobDetails?.sec_scoring === "CGPA" ? { min: 0, max: 10 } : { min: 0, max: 4 },
                        style: {
                          padding: "16px",
                        },
                      }}
                      label="Please enter your Round Name"
                      value={typedRoundName}
                      onChange={(e) => {
                        setTypedRoundName(e.target.value);
                        setJobDetailsError({
                          ...jobDetailsError,
                          rounds: null,
                        });
                      }}
                      error={jobDetailsError.rounds ? true : false}
                      helperText={<span>{jobDetailsError.rounds}</span>}
                    />
                    <Button
                      color="primary"
                      style={{ display: "flex", marginTop: -5, marginLeft: 10 }}
                      onClick={() => {
                        setCurrentRound(null);
                      }}
                    >
                      Click here to select Round from Dropdown
                    </Button>
                  </>
                ) : (
                  <Autocomplete
                    id="country-select-demo"
                    options={jobRoundList}
                    getOptionLabel={(option) => option.name}
                    value={currentRound}
                    disabled={disabledFields?.rounds}
                    onChange={(e, newValue) => {
                      setCurrentRound(newValue);

                      setJobDetailsError({ ...jobDetailsError, rounds: null });
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        required
                        label="Select Job Round"
                        inputProps={{
                          ...params.inputProps,
                        }}
                        InputLabelProps={{
                          className: classes.floatingLabelFocusStyle,
                        }}
                        error={jobDetailsError.rounds ? true : false}
                        helperText={<span>{jobDetailsError.rounds}</span>}
                      />
                    )}
                  />
                )}
              </Grid>
            </Grid>

            <TextField
              variant="outlined"
              margin="normal"
              InputLabelProps={{
                className: classes.floatingLabelFocusStyle,
              }}
              // InputProps={{
              //   inputComponent: NumberFormatCustom,
              // }}
              // inputProps={{
              //   style: {
              //     padding: "16px",
              //   },
              // }}
              InputProps={{
                inputProps: { min: 0 },
                style: {
                  padding: "16px",
                },
                endAdornment: (
                  <InputAdornment position="start">mins</InputAdornment>
                ),
              }}
              required
              label="Duration of the Round"
              name="Duration of the Round"
              value={parseInt(currentDuration)}
              onWheel={() => document.activeElement.blur()}
              disabled={disabledFields?.rounds}
              onChange={(e) => {
                if (parseInt(e.target.value) !== 0) {
                  setCurrentDuration(parseInt(e.target.value));
                  setJobDetailsError({
                    ...jobDetailsError,
                    round_duration: null,
                  });
                }
              }}
              style={{ width: "20%" }}
              type="number"
              error={jobDetailsError.round_duration ? true : false}
              helperText={<span>{jobDetailsError.round_duration}</span>}
              // InputLabelProps={{ shrink: true }}
            />
            <br />
            <FormControl component="fieldset" style={{ marginTop: "10px" }}>
              {/* <FormLabel component="legend">Gender</FormLabel> */}

              <RadioGroup
                defaultValue="female"
                aria-label="gender"
                name="customized-radios"
                className={classes.customize}
                value={venueType}
                onChange={(e) => {
                  setVenueType(e.target.value);
                }}
              >
                <Typography variant="body2" style={{ marginRight: "7px" }}>
                  Mode of Interview:
                </Typography>
                <FormControlLabel
                  value="ONLINE"
                  disabled={disabledFields?.rounds}
                  control={<StyledRadio />}
                  label={<Typography variant="body2">Online</Typography>}
                />
                <FormControlLabel
                  value="OFFLINE"
                  disabled={disabledFields?.rounds}
                  control={<StyledRadio />}
                  label={<Typography variant="body2">Offline</Typography>}
                />
              </RadioGroup>
            </FormControl>
            <br />
            <TextField
              variant="outlined"
              InputLabelProps={{
                className: classes.floatingLabelFocusStyle,
              }}
              inputProps={{
                style: {
                  padding: "16px",
                },
              }}
              margin="normal"
              fullWidth
              InputProps={{ classes: { root: classes.inputRoot } }}
              label={
                venueType === "ONLINE"
                  ? "Link for the Meeting"
                  : "Address for the Meeting"
              }
              value={venue}
              disabled={disabledFields?.rounds}
              onChange={(e) => {
                setVenue(e.target.value);
                setJobDetailsError({ ...jobDetailsError, venue: null });
              }}
              error={jobDetailsError.venue ? true : false}
              helperText={<span>{jobDetailsError.venue}</span>}
            />
            <br />
            <br />
            <Grid item xs={2} className={classes.btngrid}>
              <Button
                variant="contained"
                className={classes.addbtn}
                color="primary"
                disabled={disabledFields?.rounds}
                onClick={() => {
                  addRounds();
                }}
              >
                Add Round
              </Button>
            </Grid>
            <br />
            {jobDetails?.rounds?.length > 0 && (
              <>
                <ArrowCards
                  data={roundList}
                  disableDelete={disabledFields?.rounds}
                  fromPostJob={true}
                  handleDeleteRounds={handleDeleteRounds}
                />
                {jobDetails?.rounds?.length > 4 && (
                  <div style={{ marginTop: "0px", marginBottom: "10px" }}>
                    <Button
                      color="primary"
                      style={{ display: "flex" }}
                      endIcon={
                        !showRoundList ? (
                          <KeyboardArrowDownSharp />
                        ) : (
                          <KeyboardArrowUpSharp />
                        )
                      }
                      onClick={() => {
                        setShowRoundList(!showRoundList);
                      }}
                    >
                      {showRoundList ? "Hide" : "View All Job Rounds"}
                    </Button>
                  </div>
                )}
                {/* <br />
                <br /> */}
              </>
            )}

            <Typography variant="body2" style={{ fontWeight: "bold" }}>
              Documents Required
            </Typography>

            <div style={{ padding: "10px" }}>
              {documents?.map((item) => (
                <FormControlLabel
                  control={
                    <Checkbox
                      size="small"
                      disabled={disabledFields?.documentsRequired}
                      checked={jobDetails?.documents?.includes(item)}
                      onChange={UpdateDocuments}
                      name={item}
                      color="primary"
                    />
                  }
                  label={<Typography variant="body2">{item}</Typography>}
                />
              ))}
            </div>
          </>
        );
    }
  }

  const placementList = useSelector((state) =>
    state?.commonData?.placementListEnded
      ? state?.commonData?.placementListEnded
      : []
  );

  // If for ejnfId there is difference of jobdetails due to previous state this is going
  // set jobdetails as per present data from store for the respective ejob id.
  useEffect(() => {
    const ejnfId = params?.job_id?.split("-")[1];
    if (ejnfId) {
      const isSame = selectedData?.job_title == jobDetails?.job_title;
      if (!isSame) {
        setJobDetails({ ...selectedData });
        // resetPlacementFlag(true);
      }
    }
  }, []);

  useEffect(() => {
    if (PlacementFlag) {
      setPlacementCycle([]);
    }
  }, [PlacementFlag]);

  useEffect(() => {
    if (placementCycleId) {
      var arr = [...placementCycle];
      var newPlacementCycle = placementList.filter(
        (item) => item.id === placementCycleId
      );
      arr.push(...newPlacementCycle);
      setPlacementCycle(uniqueArrayWithId(arr, "id"));
    }
  }, [placementList]);
  const [openChangeCollege, setOpenChangeCollege] = useState(false);
  const handleOpenCollege = () => {
    setOpenChangeCollege(true);
  };
  const handleCloseCollege = () => {
    setOpenChangeCollege(false);
  };
  const [showMore, setShowMore] = React.useState(false);

  const deleteChip = (id) => {
    const updatedCollegeList = collegesList.map((college) => {
      if (college.id === id) {
        return {
          ...college,
          checked: false,
          placementCycles: college.placementCycles.map((cycle) => ({
            ...cycle,
            checked: false,
          })),
        };
      }
      return college;
    });

    setCollegesList(updatedCollegeList);
  };

  const [openChangeCourse, setOpenChangeCourse] = useState(false);
  const handleOpenCourse = () => {
    setOpenChangeCourse(true);
  };
  const handleCloseCourse = () => {
    setOpenChangeCourse(false);
  };

  const [allDegreesSelected, setAllDegreesSelected] = useState([]);
  useEffect(
    () => console.log("allDegreesSelected", allDegreesSelected),
    [allDegreesSelected]
  );
  useEffect(() => {
    const getUniqueDegreesWithSelectedSpecializations = () => {
      const uniqueDegreesMap = new Map();
      console.log("allCollegeCoursesSet", allCollegeCoursesSet);
      Object?.keys(allCollegeCoursesSet)?.forEach((collegeKey) => {
        const collegeCourses = allCollegeCoursesSet[collegeKey];

        collegeCourses?.forEach((education) => {
          education?.degrees?.forEach((degree) => {
            const hasSelectedSpecialization = degree?.specialization?.some(
              (spec) => spec?.selected
            );

            if (hasSelectedSpecialization) {
              uniqueDegreesMap.set(degree.degree_id, degree);
            }
          });
        });
      });

      return Array.from(uniqueDegreesMap.values());
    };
    if (allCollegeCoursesSet)
      setAllDegreesSelected(getUniqueDegreesWithSelectedSpecializations());
  }, [allCollegeCoursesSet]);

  return (
    <>
      <Paper className={classes.paper}>
        <Paper className={classes.rightpaper}>
          <Typography
            variant="h4"
            style={{ paddingTop: "20px", paddingBottom: "10px" }}
          >
            Post {jobDetails?.job_type_id === 1 ? "Internship" : "Job"}
          </Typography>
          {
            <div
              className={classes.formControl}
              style={{ marginBottom: "10px" }}
            >
              {/* <Autocomplete
                id="country-select-demo"
                options={placementList}
                getOptionLabel={(option) => option.name}
                value={placementCycle}
                disabled={
                  disabledFields?.placementCycle ||
                  params?.job_id?.includes("edit")
                }
                onChange={(e, newValue) => {
                  var arr = [...placementCycle];
                  arr.push(newValue);
                  setPlacementCycle(uniqueArrayWithId(arr, "id"));

                  setJobDetailsError({
                    ...jobDetailsError,
                    placement_cycle: null,
                  });
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    label={"Placement Cycle"}
                    inputProps={{
                      ...params.inputProps,
                    }}
                    InputLabelProps={{
                      className: classes.floatingLabelFocusStyle,
                    }}
                    error={jobDetailsError?.placement_cycle ? true : false}
                    helperText={<span>{jobDetailsError?.placement_cycle}</span>}
                  />
                )}
              /> */}
              <Box
                className={classes.placementBox}
                style={{
                  border: "1px solid #C4C4C4",
                  borderColor:
                    !collegesList.filter((college) => {
                      return (
                        college.checked &&
                        college.placementCycles.some((cycle) => cycle.checked)
                      );
                    })?.length && jobDetailsError?.placement_cycle
                      ? "#dc3545"
                      : "#C4C4C4",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Box
                  style={{
                    backgroundColor: "white",
                    position: "absolute",
                    top: "-12px",
                    left: 10,
                  }}
                >
                  <span
                    style={{
                      color:
                        !collegesList.filter((college) => {
                          return (
                            college.checked &&
                            college.placementCycles.some(
                              (cycle) => cycle.checked
                            )
                          );
                        })?.length && jobDetailsError?.placement_cycle
                          ? "#dc3545"
                          : "#7f7f7f",
                      fontSize: "10px",
                      padding: "0 5px",
                    }}
                  >
                    {` ${entityInfo?.college} and Placement Cycle*`}
                  </span>
                </Box>
                <Box
                  style={{
                    display: "flex",
                    alignItems: "center",
                    paddingLeft: "5px",
                    justifyItems: "center",
                  }}
                >
                  {jobId && !collegesList.length && (
                    <span>
                      <CustomButtonCircularProgress isWhite={false} />
                    </span>
                  )}
                  <div className={classes.rootchip}>
                    {!showMore
                      ? collegesList
                          .filter((college) => {
                            return (
                              college.checked &&
                              college.placementCycles.some(
                                (cycle) => cycle.checked
                              )
                            );
                          })
                          ?.slice(0, 5)
                          ?.map((data, index) => (
                            <Chip
                              className={classes.chip}
                              color="primary"
                              variant="outlined"
                              size="small"
                              label={data?.details?.name}
                              // onDelete={() => deleteChip(data?.id)}
                              // deleteIcon={<CloseIcon />}
                            />
                          ))
                      : collegesList
                          .filter((college) => {
                            return (
                              college.checked &&
                              college.placementCycles.some(
                                (cycle) => cycle.checked
                              )
                            );
                          })
                          ?.map((data, index) => (
                            <Chip
                              className={classes.chip}
                              color="primary"
                              variant="outlined"
                              size="small"
                              label={data?.details?.name}
                              // onDelete={() => deleteChip(data?.id)}
                              // deleteIcon={<CloseIcon />}
                            />
                          ))}
                    {collegesList.filter((college) => {
                      return (
                        college.checked &&
                        college.placementCycles.some((cycle) => cycle.checked)
                      );
                    })?.length > 5 && (
                      <Button
                        color="primary"
                        onClick={() => {
                          setShowMore(!showMore);
                        }}
                      >
                        {" "}
                        {!showMore
                          ? `+${
                              collegesList.filter((college) => {
                                return (
                                  college.checked &&
                                  college.placementCycles.some(
                                    (cycle) => cycle.checked
                                  )
                                );
                              })?.length - 5
                            } more`
                          : "Hide"}
                      </Button>
                    )}
                  </div>
                </Box>
                <Box
                  style={{ position: "absolute", top: "15px", right: "15px" }}
                >
                  <Typography
                    color="primary"
                    style={{ cursor: "pointer" }}
                    onClick={handleOpenCollege}
                  >
                    Change
                  </Typography>
                </Box>
              </Box>

              {!collegesList.filter((college) => {
                return (
                  college.checked &&
                  college.placementCycles.some((cycle) => cycle.checked)
                );
              })?.length &&
                jobDetailsError?.placement_cycle && (
                  <span
                    style={{
                      paddingLeft: "15px",
                      color: "#dc3545",
                      fontSize: "12px",
                    }}
                  >
                    {jobDetailsError?.placement_cycle}
                  </span>
                )}
              {openChangeCollege && (
                <CollegesPostedJobsDialog
                  open={openChangeCollege}
                  handleClose={handleCloseCollege}
                  collegesList={collegesList}
                  setCollegesList={setCollegesList}
                  setSearchState={setSearchState}
                  searchState={searchState}
                  selectedCollegeIds={selectedCollegeIds}
                  setSelectedCollegeIds={setSelectedCollegeIds}
                  setSelectedCollegeChangeTrigger={
                    setSelectedCollegeChangeTrigger
                  }
                  nestedColleges={nestedColleges}
                  groups={groups}
                />
              )}
              {openChangeCourse && (
                <AddEligibleCoursesDialog
                  open={openChangeCourse}
                  handleClose={handleCloseCourse}
                  allCollegeCoursesSet={allCollegeCoursesSet}
                  setallCollegeCoursesSet={setallCollegeCoursesSet}
                  collegesList={collegesList}
                  setCollegesList={setCollegesList}
                  loading={collegeCoursesLoader}
                  setGlobalCoursesList={setGlobalCoursesList}
                  globalCoursesList={globalCoursesList}
                  nestedColleges={nestedColleges}
                  groups={groups}
                />
              )}
            </div>
          }

          {Object.keys(groupFieldList)?.length > 0 &&
            Object.keys(groupFieldList)?.map((item, index) => (
              <>
                {item !== "top" && (
                  <>
                    <Box style={{ display: "flex", alignItems: "center" }}>
                      <Typography
                        variant="h6"
                        style={{ marginTop: "10px", marginBottom: "10px" }}
                      >
                        {item}
                      </Typography>
                      {item === "Eligibility" ? (
                        <Box style={{ display: "flex" }}>
                          <Tooltip
                            title="You can modify eligible courses, currently prefilled by selected colleges"
                            placement="right"
                          >
                            <InfoOutlinedIcon
                              style={{
                                cursor: "pointer",
                                fontSize: "18px",
                                marginLeft: 5,
                              }}
                            />
                          </Tooltip>
                        </Box>
                      ) : (
                        ""
                      )}
                    </Box>
                  </>
                )}

                {groupFieldList[item].map((item2, index2) => (
                  <>{getFieldContent(item2.field_type, item2, index2)}</>
                ))}
              </>
            ))}

          {jobAction === "edit" && (
            <>
              <br />
              <Typography variant="body2" style={{ fontWeight: "bold" }}>
                Reason to Edit the Job
              </Typography>
              <TextField
                variant="outlined"
                margin="normal"
                fullWidth
                inputProps={{
                  style: {
                    padding: "16px",
                  },
                }}
                multiline
                rows="2"
                InputLabelProps={{
                  className: classes.floatingLabelFocusStyle,
                }}
                label={`Why you want to edit this Job?`}
                value={editReason}
                onChange={(e) => {
                  setEditReason(e.target.value);
                  setEditReasonError("");
                }}
                style={{ marginBottom: 10 }}
                error={editReasonError ? true : false}
                helperText={<span>{editReasonError}</span>}
              />
            </>
          )}
        </Paper>
      </Paper>
    </>
  );
}
